import React, { useState } from 'react'
import Dialog from 'src/components/Dialog'

import { AnnotationContent, ModalTitle } from './components'

import './index.styl'

const Annotation = ({ visible, setVisible, structureElementsLength }) => {
  const [radioValue, setRadioValue] = useState(15)

  return (
    <Dialog
      visible={visible}
      setVisible={setVisible}
      height={500}
      title='Аннотация'
      titleBody={<ModalTitle radioValue={radioValue} setRadioValue={setRadioValue} />}
    >
      <div className='annotation-wrapper'>
        <AnnotationContent
          radioValue={radioValue}
          structureElementsLength={structureElementsLength}
        />
      </div>
    </Dialog>
  )
}

export default Annotation
