import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Input, Tooltip, Modal } from 'antd'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import {
  getCheckedDocs,
  setCurrentPage,
} from 'src/modules/reducers/docsListReducer/docsList-actions'
import {
  docsListObject,
  uploadDocs,
  filtersSelector,
  sortDocsListSelector,
} from 'src/modules/selectors'
import {
  fetchCollections,
  removeUploadedDoc,
  setCurrentOption,
  setCurrentOptionName,
} from 'src/modules/reducers/uploadStepsReducer/uploadSteps-actions'
import DocumentsListItem from 'src/components/DocumentsListItem/DocumentsListItem'
import { usePrevious, useSockets } from 'src/common'
import Dropzone from 'src/components/Dropzone'
import { SvgIcon } from 'src/components/SvgIcon'
import { DrawerFilter } from 'src/components/Drawer'

import { SearchOutlined } from '@ant-design/icons'

import { getSkeletonsList } from './helpers'
import { Filters } from './components/Filters'
import { StatusMessage } from './components'
import './index.styl'

const MainPage = () => {
  const { options: cascaderOptions, currentDocName } = useSelector(uploadDocs)
  const { docsList, pageSize, totalResults, currentPage } = useSelector(docsListObject)
  const sortDocsList = useSelector(sortDocsListSelector)
  const filters = useSelector(filtersSelector)
  const [isSuccessLoading, setIsSuccessLoading] = useState(false)
  const [skeleton, setSkeleton] = useState({ show: false, numberOf: pageSize })
  const dispatch = useDispatch()
  const prevValue = usePrevious({ currentPage })
  const [filtersVisible, setFiltersVisible] = useState(false)
  const [docsListFilterResult, setDocsListFilterResult] = useState()
  const [collectionsFilterResult, setCollectionsFilterResult] = useState()
  const [subcollectionsFilterResult, setSubcollectionsFilterResult] = useState()
  const [formClear, setFormClear] = useState(false)
  const [modalIsVisible, setModalVisible] = useState(false)
  const [selectItem, setSelectItem] = useState(null)
  const [selectChildrenItem, setSelectChildrenItem] = useState(null)

  useSockets()

  const filterList = list => {
    return list.filter(listItem => {
      return Object.keys(filters).every(filter => {
        switch (filter) {
          case 'verificationPassedCheckbox':
            return listItem.status.includes('is_completed')
          case 'verificationFailedCheckbox':
            return listItem.status.includes('vectorizing')
          case 'rangePicker':
            const currentDate = moment(listItem.updated).format('YYYY-MM-DD')
            return moment(currentDate).isBetween(filters.rangePicker[0], filters.rangePicker[1])
          case 'selectSort':
            return true
          default:
            return true
        }
      })
    })
  }

  const setFilter = (list, filterResult) => {
    return list.filter(listItem => {
      const { name, label } = listItem
      const getIncludesToList = value => {
        return value?.toLowerCase().includes(filterResult?.toLowerCase()) && listItem
      }

      return getIncludesToList(name || label)
    })
  }

  const updateDocsList = useMemo(() => {
    if (docsList.length && docsListFilterResult) {
      return setFilter(docsList, docsListFilterResult)
    }

    const filterDocsList = filterList(sortDocsList)

    return filterDocsList
  }, [docsList, docsListFilterResult, sortDocsList, filters])

  const updateCollections = useMemo(() => {
    if (cascaderOptions.length && collectionsFilterResult) {
      return setFilter(cascaderOptions, collectionsFilterResult)
    }

    return cascaderOptions
  }, [cascaderOptions, collectionsFilterResult])

  const updateSubcollections = useMemo(() => {
    if (selectItem?.children.length && subcollectionsFilterResult) {
      return setFilter(selectItem.children, subcollectionsFilterResult)
    }

    return selectItem?.children
  }, [selectItem?.children, subcollectionsFilterResult])

  const fetchDocsObject = useCallback(async () => {
    await dispatch(getCheckedDocs(pageSize, currentPage))
  }, [dispatch, currentPage, pageSize])

  const fetchDocsWithSkeleton = useCallback(
    async numberOf => {
      setSkeleton({ show: true, numberOf })
      await fetchDocsObject()
      setSkeleton({ show: false, numberOf })
    },
    [setSkeleton, fetchDocsObject],
  )

  useEffect(() => {
    dispatch(fetchCollections())
  }, [dispatch])

  useEffect(() => {
    docsList.length === 0 && fetchDocsWithSkeleton(pageSize)
  }, [docsList.length, fetchDocsWithSkeleton, pageSize])

  useEffect(() => {
    prevValue.currentPage !== currentPage && fetchDocsWithSkeleton(3)
  }, [fetchDocsWithSkeleton, currentPage, prevValue])

  const skeletonList = useMemo(() => {
    return getSkeletonsList(skeleton.numberOf)
  }, [skeleton.numberOf])

  const handleFinishUpload = useCallback(() => {
    setIsSuccessLoading(false)
    dispatch(removeUploadedDoc())
  }, [dispatch])

  const handleShowMore = () => {
    dispatch(setCurrentPage(currentPage + 1))
  }

  const handleFiltersClose = () => {
    setFiltersVisible(false)
  }

  const handleFiltersApply = () => {
    setFiltersVisible(false)
  }

  const handleFiltersClear = () => {
    setFormClear(!formClear)
  }

  const handleDrawerShow = () => {
    setFiltersVisible(true)
  }

  const handleDocsListFilterResult = ({ target }) => {
    setDocsListFilterResult(target.value)
  }

  const handleCollectionsFilterResult = ({ target }) => {
    setCollectionsFilterResult(target.value)
  }

  const handleSubcollectionsFilterResult = ({ target }) => {
    setSubcollectionsFilterResult(target.value)
  }

  const handleVisibleModal = value => () => {
    setModalVisible(value)
  }

  const handleItemClick = item => () => {
    setSelectItem(item)
  }

  const handleChildrenItemClick = item => () => {
    dispatch(setCurrentOptionName(item.value))
    setSelectChildrenItem(item)
    dispatch(setCurrentOption(item))
  }

  return (
    <div className='main-page-wrapper'>
      <div className='filters-container'>
        <Button
          type='primary'
          onClick={handleVisibleModal(true)}
          icon={<SvgIcon iconName='file' />}
        >
          Проверить документ
        </Button>

        <Input
          className='check-result-input'
          placeholder='Поиск документа'
          onChange={handleDocsListFilterResult}
          prefix={
            <Tooltip>
              <SearchOutlined className='search-icon' />
            </Tooltip>
          }
        />

        <Button onClick={handleDrawerShow} icon={<SvgIcon iconName='filter' />}>
          Фильтры
        </Button>
      </div>

      <DrawerFilter
        visible={filtersVisible}
        onApply={handleFiltersApply}
        onClose={handleFiltersClose}
        onClear={handleFiltersClear}
      >
        <Filters isClear={formClear} filterValues={filters} totalResults={totalResults} />
      </DrawerFilter>

      {updateDocsList.map(docItem => (
        <DocumentsListItem key={docItem.guid} docItem={docItem} />
      ))}

      {totalResults > docsList.length && !skeleton.show ? (
        <Button className='ant-btn-link-primary button-more' type='link' onClick={handleShowMore}>
          Показать больше
        </Button>
      ) : (
        ''
      )}

      {skeleton.show ? skeletonList : ''}

      <Modal
        className='file-upload-modal'
        title='Загрузка документов на проверку'
        width={800}
        height={600}
        visible={modalIsVisible}
        onCancel={handleVisibleModal(false)}
        footer={null}
      >
        <div className='collection-wrapper'>
          <div className='collection-title'>Выбор коллекции</div>
          <div className='collection-container'>
            <div className='collection-subcontainer'>
              <Input
                className='collection-input'
                placeholder='Введите название'
                onChange={handleCollectionsFilterResult}
              />
              <div className='collection-items'>
                {updateCollections?.map((item, index) => (
                  <p
                    key={index}
                    className={`collection-item ${
                      item.value === selectItem?.value ? 'active' : ''
                    }`}
                    onClick={handleItemClick(item)}
                  >
                    {item.value}
                  </p>
                ))}
              </div>
            </div>
            <div className='collection-subcontainer'>
              <Input
                className='collection-input'
                placeholder='Введите название'
                onChange={handleSubcollectionsFilterResult}
              />
              <div className='collection-items'>
                {updateSubcollections?.map((item, index) => (
                  <p
                    key={index}
                    className={`collection-item ${
                      item.value === selectChildrenItem?.value ? 'active' : ''
                    }`}
                    onClick={handleChildrenItemClick(item)}
                  >
                    {item.label}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className='upload-document-container'>
          <div className='collection-title'>Загрузка документов</div>
          {isSuccessLoading ? (
            <StatusMessage currentDocName={currentDocName} onOkButtonClick={handleFinishUpload} />
          ) : (
            <Dropzone setIsSuccessLoading={setIsSuccessLoading} />
          )}
        </div>
      </Modal>
    </div>
  )
}

export default MainPage
