import {
  SET_TASK_ID,
  SET_SOURCE_DOCUMENT_STRUCTURE_ELEMENTS,
  SET_COMPARISON_RESULTS,
  SET_SIMILAR_DOCS_STRUCTURE,
  SET_HISTORY_DOCS,
  SET_RESULTS_ANCHORS,
  SET_RESULTS_ROOT_ELEMENT,
  SET_CURRENT_DOM_META_INFO,
  MERGE_USER_PROFILE,
  SET_FILTERS,
  SORT_LEVEL_BTN,
} from './main-constants'

export const sessionInitialState = {
  task_id: '',
  currentDocMetaInfo: {
    name: '',
    kindShort: '',
    number: '',
  },
  source_document_structure_elements: null,
  comparison_results: [],
  resultsAnchorsArr: [],
  resultsRootElem: null,
  similar_document_structure: null,
  historyDocs: {
    docsList: [],
    listSize: 4,
  },
  userProfile: {
    firstname: 'Константин',
    lastname: 'Константинопольский',
    email: 'Sergeev@gmail.com',
  },
  filters: {
    rangePicker: null,
    selectSort: 'byVerificationDate',
    verificationPassedCheckbox: false,
    verificationFailedCheckbox: false,
  },
  sortLevelBtn: 'asc',
}

export const mainReducer = (state = sessionInitialState, action) => {
  switch (action.type) {
    case SORT_LEVEL_BTN: {
      return {
        ...state,
        sortLevelBtn: action.payload,
      }
    }
    case SET_RESULTS_ROOT_ELEMENT: {
      return {
        ...state,
        resultsRootElem: action.payload,
      }
    }
    case SET_RESULTS_ANCHORS: {
      return {
        ...state,
        resultsAnchorsArr: action.payload,
      }
    }
    case SET_HISTORY_DOCS: {
      return {
        ...state,
        historyDocs: {
          ...state.historyDocs,
          docsList: action.payload,
        },
      }
    }
    case SET_SIMILAR_DOCS_STRUCTURE: {
      return {
        ...state,
        similar_document_structure: action.payload,
      }
    }
    case SET_SOURCE_DOCUMENT_STRUCTURE_ELEMENTS: {
      return {
        ...state,
        source_document_structure_elements: action.payload,
      }
    }
    case SET_COMPARISON_RESULTS: {
      return {
        ...state,
        comparison_results: action.payload.results,
        task_id: action.payload.taskId,
      }
    }
    case SET_TASK_ID: {
      return {
        ...state,
        task_id: action.payload,
      }
    }
    case SET_CURRENT_DOM_META_INFO: {
      return {
        ...state,
        currentDocMetaInfo: action.payload,
      }
    }
    case SET_FILTERS: {
      return {
        ...state,
        filters: action.payload,
      }
    }
    case MERGE_USER_PROFILE: {
      return {
        ...state,
        userProfile: { ...state.userProfile, ...action.payload },
      }
    }
    default:
      return state
  }
}
