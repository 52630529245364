import React, { useState } from 'react'
import { Button, Form, Input, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { userProfile } from 'src/modules/selectors'
import { mergeUserProfile } from 'src/modules/reducers/mainReducer/main-actions'
import { setTrimmedUserNameFields, validateName } from 'src/common/utils'

import { PassUpdateDialog, SuffixMenu } from './components'

import './index.styl'

const fieldUpdateMessages = {
  lastname: 'Ваше фамилия обновлена',
  firstname: 'Ваше имя обновлено',
  email: 'Ваш Email обновлен',
}

const UserProfilePage = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const userData = useSelector(userProfile)
  const [showDialog, setShowDialog] = useState(false)

  const handleValuesChange = changedValues => {
    setTrimmedUserNameFields(changedValues, form.setFieldsValue)
  }

  const handleFieldNameUpdate = fieldName => () => {
    const isTouched = form.isFieldTouched(fieldName)
    const newValue = form.getFieldValue(fieldName)
    const isUpdated = userData[fieldName] !== newValue
    if (isTouched && isUpdated) {
      dispatch(mergeUserProfile({ [fieldName]: newValue }))
      message.success(fieldUpdateMessages[fieldName])
    }
  }

  const handleFieldReset = fieldName => () => {
    form.resetFields([fieldName])
  }

  return (
    <section className='profile-wrapper'>
      <div className='profile-title'>
        <h2>Персональные данные</h2>
      </div>

      <Form
        form={form}
        name='profile'
        initialValues={userData}
        onValuesChange={handleValuesChange}
        colon={false}
        requiredMark={false}
      >
        <Form.Item
          label='Фамилия'
          name='lastname'
          rules={[{ required: true, message: 'Пожалуйста, укажите свою фамилию.' }, validateName]}
        >
          <Input
            placeholder='Введите свою фамилию'
            type='text'
            suffix={
              <SuffixMenu
                onChangeClick={handleFieldNameUpdate('lastname')}
                onCancelClick={handleFieldReset('lastname')}
              />
            }
          />
        </Form.Item>

        <Form.Item
          label='Имя'
          name='firstname'
          rules={[{ required: true, message: 'Пожалуйста, укажите свое имя.' }, validateName]}
        >
          <Input
            placeholder='Введите свое имя'
            type='text'
            suffix={
              <SuffixMenu
                onChangeClick={handleFieldNameUpdate('firstname')}
                onCancelClick={handleFieldReset('firstname')}
              />
            }
          />
        </Form.Item>

        <Form.Item
          name='email'
          label='E-mail'
          rules={[
            {
              type: 'email',
              message: 'Пожалуйста, укажите корректный E-mail.',
            },
            {
              required: true,
              message: 'Пожалуйста, укажите свой E-mail.',
            },
          ]}
        >
          <Input
            placeholder='Введите e-mail'
            type='email'
            suffix={
              <SuffixMenu
                onChangeClick={handleFieldNameUpdate('email')}
                onCancelClick={handleFieldReset('email')}
              />
            }
          />
        </Form.Item>

        <Form.Item name='password' label='Пароль'>
          <Button onClick={() => setShowDialog(true)} type='primary update-btn'>
            Изменить
          </Button>
        </Form.Item>
      </Form>

      <PassUpdateDialog setVisible={setShowDialog} visible={showDialog} />
    </section>
  )
}

export default UserProfilePage
