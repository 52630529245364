import axios from 'axios'
import Cookies from 'js-cookie'

import { logout } from '../reducers/sessionReducer/session-actions'
export const instance = axios.create({
  baseURL: '/api/v1',
})

export const requestInterceptor = store => {
  instance.interceptors.request.use(req => {
    const token = store.getState().session.token
    req.headers['X-CSRFToken'] = Cookies.get('csrftoken')
    if (token) req.headers.Authorization = `Token ${token}`
    return req
  })
}

export const responseInterceptor = store => {
  const { dispatch } = store
  instance.interceptors.response.use(
    res => res,
    error => {
      if (error.response.status === 401) {
        dispatch(logout())
      }
      throw error
    },
  )
}
