import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import cn from 'classnames'
import { useParams, Link, useLocation } from 'react-router-dom'
import { Element } from 'react-scroll'
import { Breadcrumb, Button, List, Skeleton } from 'antd'
import { getSimilarDocStructure, setTaskId } from 'src/modules/reducers/mainReducer/main-actions'
import { getURL } from 'src/modules/router/getRoutes'
import { SvgIcon } from 'src/components/SvgIcon'

import { scrollToElement } from './helpers'

import './index.styl'

const SimilarDocs = () => {
  const { task_id, similar_document_structure } = useSelector(state => state.main)

  const dispatch = useDispatch()
  const params = useParams()
  const location = useLocation()
  const [elementsStructure, setElementStructure] = useState([])
  const [isStructureLoading, setIsStructureLoading] = useState(true)
  const [elementNumber, setElementNumber] = useState(null)
  const [docStructureElemNumber, setDocStructureElemNumber] = useState(null)

  const scrollStructure = useCallback(() => {
    docStructureElemNumber && scrollToElement('Source-root', docStructureElemNumber)
  }, [docStructureElemNumber])

  const scrollSimilarDocument = useCallback(() => {
    elementNumber && scrollToElement('similar-content-wrapper', elementNumber)
  }, [elementNumber])

  // 1. set variables
  useEffect(() => {
    const setParameters = async () => {
      await dispatch(getSimilarDocStructure(paramsSimilarDocId))
      setElementNumber(elementNumber)
      setDocStructureElemNumber('structure_' + elementNumber)
      dispatch(setTaskId(task_id))
    }

    const paramsSimilarDocId = params.similarDocId
    const query = new URLSearchParams(location.search)
    const elementNumber = query.get('element_number')
    const task_id = query.get('task_id')

    paramsSimilarDocId && elementNumber && task_id && setParameters()
  }, [dispatch, params.similarDocId, location.search])

  // 2. set elementStructure after fetching is complete
  useEffect(() => {
    setElementStructure(similar_document_structure?.document_structure_elements || [])
  }, [similar_document_structure])

  // 3. finish loading after fetching document is complete
  useEffect(() => {
    elementsStructure.length &&
      docStructureElemNumber &&
      elementNumber &&
      setIsStructureLoading(false)
  }, [elementsStructure, docStructureElemNumber, elementNumber])

  // 4. scroll the structure to the current element after loading is complete
  useEffect(() => {
    !isStructureLoading && scrollStructure()
  }, [isStructureLoading, scrollStructure])

  // 5. scroll similar document to the current element item when the element number is updated
  useEffect(() => {
    !isStructureLoading && elementNumber && scrollSimilarDocument()
  }, [elementNumber, isStructureLoading, scrollSimilarDocument])

  const setCurrentElement = elementNumber => {
    setElementNumber(elementNumber)
  }

  return (
    <div className='MainPage-root similar-docs'>
      <Breadcrumb
        className='breadcrumb whole-row'
        separator={<SvgIcon className='separator-icon' iconName='arrow-right' />}
      >
        <Breadcrumb.Item>
          <Link to='/'>Главная</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={getURL.results(task_id)}>Результаты проверки</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Схожий нормативный документ</Breadcrumb.Item>
      </Breadcrumb>
      <div className='whole-row currentDocName-wrapper'>
        <h3 className='MainPage-currentDocName'>
          {similar_document_structure?.name || 'Загрузка...'}
        </h3>
        <Button type='link ant-btn-link-primary'>Скачать документ</Button>
      </div>
      <div className='Mainpage-paragraphs'>
        <div className='Mainpage-header'>
          <h3 className='Mainpage-source-title'>Структура документа</h3>
        </div>

        <div className='Source-root element' id='Source-root'>
          <div className='source-all-text'>
            {!isStructureLoading ? (
              <List
                dataSource={elementsStructure}
                renderItem={({ element_number, full_text, path }) => (
                  <List.Item
                    className='similar-docs-list-item'
                    onClick={() => setCurrentElement(element_number)}
                  >
                    <Element name={'structure_' + element_number}>
                      <div
                        style={{ paddingLeft: (path.length - 1) * 10 }}
                        className={cn('similar-docs-item', {
                          active: element_number === elementNumber,
                        })}
                      >
                        {full_text}
                      </div>
                    </Element>
                  </List.Item>
                )}
              />
            ) : (
              <Skeleton active />
            )}
          </div>
        </div>
      </div>
      <div className='Mainpage-paragraphs'>
        <div className='Mainpage-header'>
          <h3 className='Mainpage-title'>Текст схожего документа</h3>
        </div>
        <div className='similar-content-wrapper element' id='similar-content-wrapper'>
          {!isStructureLoading ? (
            <div className='similar-content-root'>
              {elementsStructure.map(({ element_number, full_text }) => (
                <Element key={element_number} name={element_number}>
                  <p className={element_number === elementNumber ? 'active-fragment' : ''}>
                    {full_text}
                  </p>
                </Element>
              ))}
            </div>
          ) : (
            <Skeleton active />
          )}
        </div>
      </div>
    </div>
  )
}

export default SimilarDocs
