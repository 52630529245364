import React from 'react'
import { useSelector } from 'react-redux'
import {
  comparisonResultsUniqDocNamesSelector,
  matchedFragmentsSelector,
} from 'src/modules/selectors'

const AnnotationContent = ({ structureElementsLength, radioValue }) => {
  const matchedFragments = useSelector(matchedFragmentsSelector)
  const comparisonResultsUniqDocNames = useSelector(comparisonResultsUniqDocNamesSelector)

  if (!matchedFragments.length) return <div>В документе не найдены схожие положения.</div>

  const smallAnnotation = (
    <>
      Проверяемый документ имеет в своем составе <strong>{structureElementsLength}</strong>{' '}
      нормативных положений, из них <strong>{matchedFragments.length}</strong> - кандидаты для
      проверки на противоречивость.
    </>
  )

  const mediumAnnotation = (
    <div style={{ marginTop: 20 }}>
      <div>Список нормативных правовых актов, в которых найдены схожие формулировки:</div>
      <ol>
        {comparisonResultsUniqDocNames.map(docName => (
          <li key={docName}>{docName}</li>
        ))}
      </ol>
    </div>
  )

  const largeAnnotation = (
    <div style={{ marginTop: 20 }}>
      <div>
        Выявленные схожие положения, которые эксперту необходимо проверить на противоречивость:
      </div>
      <ol>
        {matchedFragments.map(({ text }, i) => (
          <li key={text + i}>{text}</li>
        ))}
      </ol>
    </div>
  )

  return (
    <>
      {smallAnnotation}
      {radioValue > 300 ? largeAnnotation : ''}
      {radioValue > 15 ? mediumAnnotation : ''}
    </>
  )
}

export const MemoizedAnnotationContent = React.memo(AnnotationContent)
