import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { isAuthenticated } from '../selectors'

function PrivateRoute({ component: Component, ...rest }) {
  const isAuth = useSelector(isAuthenticated)

  return (
    <Route
      {...rest}
      render={props => (isAuth ? <Component {...props} /> : <Redirect to='/login' />)}
    />
  )
}

export default PrivateRoute
