import React from 'react'
import { Menu, Dropdown } from 'antd'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { logout } from 'src/modules/reducers/sessionReducer/session-actions'
import { clearState } from 'src/modules/reducers/cashedResultsReducer/cashedResults-actions'

import { SvgIcon } from '../SvgIcon'

import './index.styl'

const UserDropdown = () => {
  const dispatch = useDispatch()

  const handleLogout = event => {
    event.preventDefault()
    dispatch(logout())
    dispatch(clearState())
  }

  const menu = (
    <Menu className='download-dropdown'>
      <Menu.Item key='profile'>
        <Link to='/profile'>Личный кабинет</Link>
      </Menu.Item>
      <Menu.Item key='logout'>
        <a onClick={event => handleLogout(event)} href='/'>
          Выйти
        </a>
      </Menu.Item>
    </Menu>
  )

  return (
    <Dropdown overlay={menu} placement='bottomRight'>
      <a href='/' className='ant-dropdown-link' onClick={e => e.preventDefault()}>
        <SvgIcon className='user-icon' iconName='user' />
      </a>
    </Dropdown>
  )
}

export default UserDropdown
