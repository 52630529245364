import React, { useMemo } from 'react'
import { Progress } from 'antd'
import { useHistory } from 'react-router'
import cn from 'classnames'
import { getURL } from 'src/modules/router/getRoutes'
import { formatDate } from 'src/common/utils'

import { SvgIcon } from '../SvgIcon'

import './DocumentListItem.styl'

const DocumentsListItem = ({ docItem }) => {
  const history = useHistory()
  const { name, task } = docItem
  const { status: taskStatus, created: taskCreated, guid: taskGuid, progress: taskProgress } = task

  const formattedTaskCreated = useMemo(() => formatDate(taskCreated), [taskCreated])

  const handleDocItem = status => {
    if (status !== 'done') return
    history.push(getURL.results(taskGuid))
  }

  return (
    <div
      className={cn('checked-doc', { 'doc-error': taskStatus !== 'done' })}
      onClick={() => handleDocItem(taskStatus)}
    >
      {taskStatus === 'processing' ? (
        <Progress
          className='icon-progress'
          type='circle'
          percent={Math.round(taskProgress)}
          strokeColor={'#00A6EE'}
          width={50}
        />
      ) : (
        <SvgIcon className={`icon-size icon-doc-${taskStatus}`} iconName='text-success' />
      )}

      <div>
        <div className='main-text'>{name}</div>
        <div className='check-date'>
          {taskStatus === 'done' ? `Проверка пройдена: ${formattedTaskCreated}` : ''}
          {taskStatus === 'error' ? 'Во время проверки произошла ошибка' : ''}
          {taskStatus === 'processing' ? 'Проверка документа ...' : ''}
        </div>
      </div>
      {taskStatus === 'done' ? <SvgIcon className='icon-right-arrow' iconName='arrow-right' /> : ''}
    </div>
  )
}

export default DocumentsListItem
