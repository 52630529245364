import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Spin } from 'antd'
import cn from 'classnames'
import { throttle } from 'lodash'
import { mainState, similarFragmentsSelector } from 'src/modules/selectors'
import { SvgIcon } from 'src/components/SvgIcon'
import { setFragmentsScroll } from 'src/modules/reducers/cashedResultsReducer/cashedResults-actions'

import './index.styl'

const stitchWidth = 16 //px

const Ruler = ({ isFragmentLoading }) => {
  const stitchWidthHalf = stitchWidth / 2
  const stitchRef = useRef(null)
  const stitchBoardRef = useRef(null)
  const { resultsAnchorsArr, resultsRootElem: rootRef } = useSelector(mainState)
  const similarFragments = useSelector(similarFragmentsSelector)
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()

  const stitchRefElem = stitchRef.current
  const stitchBoardElem = stitchBoardRef.current

  const { left: stitchZoneStart, right: stitchZoneEnd, width: stitchZoneWidth } = useMemo(() => {
    const stitchBoardElemParams = stitchBoardElem?.getBoundingClientRect()
    if (stitchBoardElemParams) {
      return stitchBoardElemParams
    }
    return { left: null, right: null, stitchZoneWidth: null }
  }, [stitchBoardElem])

  useEffect(() => {
    const isAnchorsLoaded = resultsAnchorsArr.length
    const isComparisonResultsEmpty = !isFragmentLoading && !similarFragments.length
    if (isAnchorsLoaded || isComparisonResultsEmpty) setLoading(false)
  }, [resultsAnchorsArr, similarFragments, isFragmentLoading])

  useEffect(() => {
    if (!rootRef || !stitchRefElem) return

    let isThrottled = false

    const setStitchPosition = () => {
      const winScroll = rootRef.scrollTop
      const height = rootRef.scrollHeight - rootRef.clientHeight
      const scrolled = (winScroll / height) * 100
      stitchRefElem.style.left = scrolled + '%'
    }

    const savePosition = event => {
      !isThrottled && dispatch(setFragmentsScroll(event.target.scrollTop))
      isThrottled = !isThrottled
    }

    rootRef.addEventListener('scroll', setStitchPosition)
    rootRef.addEventListener('scroll', throttle(savePosition, 1000))
    return () => {
      rootRef.removeEventListener('scroll', setStitchPosition)
      rootRef.removeEventListener('scroll', throttle(savePosition))
    }
  }, [rootRef, stitchRefElem, dispatch])

  const scrollResultZone = scrollPercent => {
    const resultRootHeight = rootRef.scrollHeight - rootRef.clientHeight
    const resultZoneScroll = (resultRootHeight / 100) * scrollPercent
    rootRef.scroll({ top: resultZoneScroll })
  }

  const scrollStitch = scrollPx => {
    stitchRefElem.style.left = scrollPx + 'px'
  }

  const stitchBoardMouseMove = ({ pageX }) => {
    if (pageX > stitchZoneStart && pageX < stitchZoneEnd) {
      const stitchLeftPx = pageX - stitchZoneStart
      const stitchLeftPercents = (stitchLeftPx / stitchZoneWidth) * 100
      scrollStitch(stitchLeftPx)
      scrollResultZone(stitchLeftPercents)
    }
  }

  const removeStitchBoardListeners = () => {
    window.removeEventListener('mousemove', stitchBoardMouseMove)
    window.removeEventListener('mousedown', stitchBoardMouseMove)
    window.removeEventListener('mouseup', removeStitchBoardListeners)
  }

  const handleStitchBoardMouseDown = () => {
    window.addEventListener('mousemove', stitchBoardMouseMove)
    window.addEventListener('mousedown', stitchBoardMouseMove)
    window.addEventListener('mouseup', removeStitchBoardListeners)
  }

  const handleClickOnSeparator = e => {
    const rulerPosition = e.target.getBoundingClientRect()
    const scrollPercent = ((e.clientX - rulerPosition.left) * 100) / e.target.clientWidth
    scrollResultZone(scrollPercent)
  }

  return (
    <div className='main-ruler-style'>
      <Spin
        spinning={loading}
        size='small'
        wrapperClassName='ruler-spin'
        indicator={<span className='ruler-loader-text'>Загрузка...</span>}
      >
        <div
          className='Ruler-root'
          style={{ position: 'relative' }}
          onClick={handleClickOnSeparator}
        >
          {resultsAnchorsArr.map(({ offsetTop, top, clientHeight, fullCitation }) => (
            <a
              className={cn({ 'full-citation': fullCitation }, 'separator')}
              style={{
                width: clientHeight + '%',
                left: (offsetTop < 100 ? offsetTop : 100) + '%',
              }}
              href={`#${top}`}
              key={offsetTop}
            >
              {''}
            </a>
          ))}
        </div>
      </Spin>
      <div className='header-arrow'>
        <div
          className='progress-container'
          ref={stitchBoardRef}
          onMouseDown={handleStitchBoardMouseDown}
        >
          <div className='progress-bar' id='myBar' style={{ left: `-${stitchWidthHalf}px` }}>
            <div className='stitch' ref={stitchRef}>
              <SvgIcon className='stitch-icon' iconName='triangle' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Ruler
