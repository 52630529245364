import {
  SET_COLLECTIONS,
  SET_CURRENT_OPTION,
  SET_CURRENT_OPTION_NAME,
  REMOVE_UPLOADED_DOC,
  SET_UPLOADED_DOC,
  SET_CURRENT_DOC_NAME,
} from './uploadSteps-constants'

const initialState = {
  currentDoc: {},
  currentDocName: '',
  options: [],
  optionName: '',
  currentOption: [],
}

export const uploadDocsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COLLECTIONS: {
      return {
        ...state,
        options: action.payload,
      }
    }
    case SET_CURRENT_OPTION_NAME: {
      return {
        ...state,
        optionName: action.payload,
      }
    }
    case SET_CURRENT_OPTION: {
      return {
        ...state,
        currentOption: action.payload,
      }
    }
    case REMOVE_UPLOADED_DOC: {
      return {
        ...state,
        currentDoc: {},
        currentDocName: '',
      }
    }
    case SET_UPLOADED_DOC: {
      return {
        ...state,
        currentDoc: action.payload,
      }
    }
    case SET_CURRENT_DOC_NAME: {
      return {
        ...state,
        currentDocName: action.payload,
      }
    }
    default:
      return state
  }
}
