import React from 'react'
import { Skeleton, Space } from 'antd'

import './SkeletonItem.styl'

export const SkeletonItem = () => (
  <Space className='skeleton-space'>
    <Skeleton.Avatar shape='square' size='large' style={{ height: 50, marginRight: 10 }} active />
    <Space className='skeleton-space-inner'>
      <Skeleton.Input
        style={{
          width: 500 + Math.random() * 1000,
          height: 18,
          marginBottom: 7,
        }}
        size='small'
        active
      />
      <Skeleton.Input active style={{ width: 350, height: 18 }} size='small' />
    </Space>
  </Space>
)
