import React, { useEffect, useState } from 'react'
import { Button, Form, Input } from 'antd'
import { useHistory } from 'react-router'
import { validatePass } from 'src/common/utils'
import { usePasswordType } from 'src/common'

import './index.styl'

const PassRecovery = () => {
  const history = useHistory()
  const [showPageSuccess, setShowPageSuccess] = useState(false)
  const [showPasswordExtra, setShowPasswordExtra] = useState(true)
  const pass = usePasswordType()
  const confirmPass = usePasswordType()

  const onFinish = () => {
    // console.log('Success:', values)
    setShowPageSuccess(true)
  }

  const onFinishFailed = () => {
    // console.log('Failed:', errorInfo)
  }

  useEffect(() => {
    showPageSuccess &&
      setTimeout(() => {
        history.push('/auth')
      }, 3000)
  }, [history, showPageSuccess])

  if (showPageSuccess)
    return (
      <div className='pass-recovery-success-text-wrapper'>
        <span>Пароль успешно изменен</span>
        <span className='pass-recovery-success-text'>
          Вы будете перенаправлены на страницу входа через 3 секунды
        </span>
      </div>
    )
  else
    return (
      <section className='auth-wrapper pass-recovery-wrapper'>
        <div className='auth-title'>
          <h2>Восстановление пароля</h2>
        </div>

        <Form
          name='register'
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          colon={false}
          requiredMark={false}
        >
          <Form.Item
            label='Новый пароль'
            name='password'
            extra={
              showPasswordExtra
                ? 'Минимум 8 знаков: строчные и заглавные латинские буквы, цифры и символы'
                : ''
            }
            rules={[
              { required: true, message: 'Пожалуйста, введите новый пароль.' },
              () => ({
                validator(rule, value) {
                  const validMessage = validatePass(value)
                  if (value && validMessage !== 'valid') {
                    setShowPasswordExtra(false)
                    return Promise.reject(validMessage)
                  } else {
                    return Promise.resolve()
                  }
                },
              }),
            ]}
          >
            <Input placeholder='Введите пароль' {...pass} />
          </Form.Item>

          <Form.Item
            label='Повтор пароля'
            name='confirm'
            dependencies={['password']}
            rules={[
              { required: true, message: 'Пожалуйста, повторите пароль.' },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject('Введенные пароли не совподают.')
                },
              }),
            ]}
          >
            <Input placeholder='Повторите пароль' {...confirmPass} />
          </Form.Item>

          <Form.Item>
            <Button type='primary auth-submit-btn' htmlType='submit'>
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </section>
    )
}

export default PassRecovery
