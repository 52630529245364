import { combineReducers } from 'redux'

import { mainReducer } from '../reducers/mainReducer/main-reducer'
import { docsListReducer } from '../reducers/docsListReducer/docsList-reducer'
import { uploadDocsReducer } from '../reducers/uploadStepsReducer/uploadDocs-reducer'
import { cashedResultsReducer } from '../reducers/cashedResultsReducer/cashedResults-reducer'
import { sessionReducer } from '../reducers/sessionReducer/session-reducer'

const createReducer = () =>
  combineReducers({
    cashedResults: cashedResultsReducer,
    uploadDocs: uploadDocsReducer,
    docsListObject: docsListReducer,
    main: mainReducer,
    session: sessionReducer,
  })
export default createReducer
