import {
  SET_ACTIVE_ELEMENT,
  SET_FRAGMENTS_SCROLL,
  SET_JAKKAR_DISTANCE_FILTER,
  SET_JAKKAR_MIN_DISTANCE_FILTER,
  SET_COSINE_DISTANCE_FILTER,
  SET_SIMILAR_FRAGMENTS_DOC_KINDS,
  SET_SIMILAR_FRAGMENTS_SORT_FIELD,
  SET_SIMILAR_FRAGMENTS_SORT_ORDER,
  SET_ACTIVE_ELEMENT_HIGHLIGHTED_FULL_TEXT,
  SET_DATE_RANGE_FILTER,
  SET_DOCUMENT_STRUCTURE_FILTERS,
  CLEAR_STATE,
  CASHED_RESULTS_SET_FILTERS,
  SET_FIRST_SORT_LEVEL_BTN,
  SET_SECOND_SORT_LEVEL_BTN,
  SET_THIRD_SORT_LEVEL_BTN,
  SET_FIRST_SORT_LEVEL,
} from './cashedResults-constants'

export const setFirstSortLevel = value => ({
  type: SET_FIRST_SORT_LEVEL,
  payload: value,
})
export const setFirstSortLevelBtn = payload => ({
  type: SET_FIRST_SORT_LEVEL_BTN,
  payload,
})
export const setSecondSortLevelBtn = payload => ({
  type: SET_SECOND_SORT_LEVEL_BTN,
  payload,
})
export const setThirdSortLevelBtn = payload => ({
  type: SET_THIRD_SORT_LEVEL_BTN,
  payload,
})
export const setFilters = payload => ({
  type: CASHED_RESULTS_SET_FILTERS,
  payload,
})
export const setJakkarDistanceFilter = payload => ({
  type: SET_JAKKAR_DISTANCE_FILTER,
  payload,
})
export const setJakkarMinDistanceFilter = payload => ({
  type: SET_JAKKAR_MIN_DISTANCE_FILTER,
  payload,
})
export const setCosineDistanceFilter = payload => ({
  type: SET_COSINE_DISTANCE_FILTER,
  payload,
})
export const setSimilarFragmentsDocKinds = payload => ({
  type: SET_SIMILAR_FRAGMENTS_DOC_KINDS,
  payload,
})
export const setSimilarFragmentsSortField = payload => ({
  type: SET_SIMILAR_FRAGMENTS_SORT_FIELD,
  payload,
})
export const setSimilarFragmentsSortOrder = payload => ({
  type: SET_SIMILAR_FRAGMENTS_SORT_ORDER,
  payload,
})
export const setFragmentsScroll = payload => ({
  type: SET_FRAGMENTS_SCROLL,
  payload,
})
export const setActiveElement = payload => ({
  type: SET_ACTIVE_ELEMENT,
  payload,
})
export const setActiveElementHighlightedFullText = payload => ({
  type: SET_ACTIVE_ELEMENT_HIGHLIGHTED_FULL_TEXT,
  payload,
})
export const setDateRangeFilter = payload => ({
  type: SET_DATE_RANGE_FILTER,
  payload,
})
export const setDocumentStructureFilters = (filterFieldName, filterFieldValue) => {
  const payload = {}
  payload[filterFieldName] = filterFieldValue

  return {
    type: SET_DOCUMENT_STRUCTURE_FILTERS,
    payload,
  }
}
export const clearState = () => ({
  type: CLEAR_STATE,
})
