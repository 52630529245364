export const SET_JAKKAR_DISTANCE_FILTER = 'SET_JAKKAR_DISTANCE_FILTER'
export const SET_JAKKAR_MIN_DISTANCE_FILTER = 'SET_JAKKAR_MIN_DISTANCE_FILTER'
export const SET_COSINE_DISTANCE_FILTER = 'SET_COSINE_DISTANCE_FILTER'
export const SET_SIMILAR_FRAGMENTS_DOC_KINDS = 'SET_SIMILAR_FRAGMENTS_DOC_KINDS'
export const SET_SIMILAR_FRAGMENTS_SORT_FIELD = 'SET_SIMILAR_FRAGMENTS_SORT_FIELD'
export const SET_SIMILAR_FRAGMENTS_SORT_ORDER = 'SET_SIMILAR_FRAGMENTS_SORT_ORDER'
export const SET_FRAGMENTS_SCROLL = 'SET_FRAGMENTS_SCROLL'
export const SET_ACTIVE_ELEMENT = 'SET_ACTIVE_ELEMENT'
export const SET_ACTIVE_ELEMENT_HIGHLIGHTED_FULL_TEXT = 'SET_ACTIVE_ELEMENT_ORIGINAL_FULL_TEXT'
export const SET_DATE_RANGE_FILTER = 'SET_DATE_RANGE_FILTER'
export const SET_DOCUMENT_STRUCTURE_FILTERS = 'SET_DOCUMENT_STRUCTURE_FILTERS'
export const CLEAR_STATE = 'CLEAR_STATE'
export const CASHED_RESULTS_SET_FILTERS = 'CASHED_RESULTS_SET_FILTERS'
export const SET_FIRST_SORT_LEVEL_BTN = 'SET_FIRST_SORT_LEVEL_BTN'
export const SET_SECOND_SORT_LEVEL_BTN = 'SET_SECOND_SORT_LEVEL_BTN'
export const SET_THIRD_SORT_LEVEL_BTN = 'SET_THIRD_SORT_LEVEL_BTN'
export const SET_FIRST_SORT_LEVEL = 'SET_FIRST_SORT_LEVEL'
