import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { Button, Form, Input, message } from 'antd'
import { fetchSession } from 'src/modules/reducers/sessionReducer/session-actions'
import { Link, useParams } from 'react-router-dom'
import { API } from 'src/api'
import { mainPageErrorMessage } from 'src/common/notifications'
import { usePasswordType } from 'src/common'

import { PassRecoveryDialog } from './PassRecoveryDialog'

import './index.styl'

const AuthPage = () => {
  const { type: passwordType, suffix: passwordSuffix } = usePasswordType()
  const [showDialog, setShowDialog] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const { registerToken } = useParams()

  useEffect(() => {
    if (registerToken) {
      API.registerConfirm(registerToken)
        .then(() => {
          message.success('Ваш email подтвержден!')
        })
        .catch(error => {
          mainPageErrorMessage(error, 'Ошибка подтверждения регистрации')
          history.push('/login')
        })
    }
  }, [registerToken, history])

  const onFinish = async ({ login, password }) => {
    await dispatch(fetchSession(login, password))
    history.push('/')
  }

  return (
    <section className='auth-wrapper'>
      <div className='auth-title'>
        <h2>Вход в систему</h2>
        <Link className='auth-link' to='/register'>
          Регистрация
        </Link>
      </div>

      <Form name='register' onFinish={onFinish} colon={false} requiredMark={false}>
        <Form.Item
          name='login'
          label='Логин'
          rules={[
            {
              required: true,
              message: 'Пожалуйста, укажите свой логин.',
            },
          ]}
        >
          <Input placeholder='Введите логин' />
        </Form.Item>

        <Form.Item
          label='Пароль'
          name='password'
          rules={[{ required: true, message: 'Пожалуйста, введите пароль.' }]}
        >
          <Input placeholder='Введите пароль' type={passwordType} suffix={passwordSuffix} />
        </Form.Item>

        <Form.Item className='auth-wrapper-submit'>
          <Button className='auth-btn' type='primary auth-submit-btn' htmlType='submit'>
            Войти
          </Button>
        </Form.Item>
      </Form>

      <div className='link-wrapper'>
        <Button className='ant-btn-link-secondary' type='link' onClick={() => setShowDialog(true)}>
          <span>Забыли пароль?</span>
        </Button>
      </div>

      <PassRecoveryDialog visible={showDialog} setVisible={setShowDialog} />
    </section>
  )
}

export default AuthPage
