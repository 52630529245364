import React, { useState } from 'react'
import { SvgIcon } from 'src/components/SvgIcon'

export const usePasswordType = () => {
  const [type, setPasswordType] = useState('password')

  const suffix = (
    <div
      className='password-icon-wrapper'
      onClick={() => setPasswordType(type === 'password' ? 'text' : 'password')}
    >
      <SvgIcon
        className='password-icon'
        iconName={`password-${type === 'password' ? 'show' : 'hide'}`}
      />
    </div>
  )

  return { type, suffix }
}
