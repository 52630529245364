import { setActiveElement } from 'src/modules/reducers/cashedResultsReducer/cashedResults-actions'
import React, { createRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import {
  setResultsAnchors,
  setResultsRootElem,
} from 'src/modules/reducers/mainReducer/main-actions'
import { activeElementHighlightedFullText, cashedResults, mainState } from 'src/modules/selectors'
import { usePrevious } from 'src/common'

import './DocumentContentElement.styl'

export const DocumentContentElement = ({ documentStructure, activeElement }) => {
  const highlightedFullTest = useSelector(activeElementHighlightedFullText)
  const { fragmentsScroll } = useSelector(cashedResults)
  const { resultsRootElem } = useSelector(mainState)
  const dispatch = useDispatch()
  const [currentFragmentScroll, setCurrentFragmentScroll] = useState(null)
  const [wrapperRef, setWrapperRef] = useState(null)
  const [elRefs, setElRefs] = React.useState([])
  const prevValue = usePrevious({ elRefs })

  useEffect(() => {
    if (wrapperRef && currentFragmentScroll === null) {
      resultsRootElem.scrollTo({ top: fragmentsScroll })
      setCurrentFragmentScroll(fragmentsScroll)
    }
  }, [fragmentsScroll, wrapperRef, currentFragmentScroll, resultsRootElem])

  useEffect(() => {
    setElRefs(elRefs =>
      Array(documentStructure.length)
        .fill()
        .map((_, i) => elRefs[i] || createRef()),
    )
  }, [documentStructure])

  useEffect(() => {
    if (!resultsRootElem) return
    const { scrollHeight, clientHeight } = resultsRootElem
    const rootElemScroll = scrollHeight - clientHeight

    const getPercents = height => {
      if (!height) return null
      return (height * 100) / rootElemScroll
    }

    const refsObj = elRefs
      .map((elemRef, i) => {
        return {
          clientHeight: getPercents(elemRef.current?.clientHeight),
          top: elemRef.current?.offsetTop,
          offsetTop: getPercents(elemRef.current?.offsetTop),
          fullCitation: documentStructure[i]?.fullCitation,
        }
      })
      .filter(item => item.top)

    prevValue.elRefs !== elRefs && dispatch(setResultsAnchors(refsObj))
  }, [dispatch, elRefs, prevValue.elRefs, documentStructure, resultsRootElem])

  const handleElementClick = item => {
    dispatch(setActiveElement(item))
  }

  const setRootRefElem = ref => {
    if (!wrapperRef && ref) {
      dispatch(setResultsRootElem(ref))
    }
  }

  return (
    <div className='Paragraphs-root' ref={setRootRefElem}>
      <div ref={ref => setWrapperRef(ref)}>
        {documentStructure.map((item, i) => {
          if (item.comparisonArr) {
            const isActive = activeElement?.element_number === item.element_number
            const getFullText = () => {
              if (isActive && highlightedFullTest) return highlightedFullTest
              return item.full_text
            }
            return (
              <p
                ref={elRefs[i]}
                id={elRefs[i]?.current?.offsetTop}
                className={cn(
                  'western',
                  'fragment-counter',
                  { 'active-fragment': isActive },
                  item.fullCitation ? 'coefficient-high' : 'coefficient-low',
                )}
                key={item.element_number}
                onClick={() => handleElementClick(item)}
              >
                <span dangerouslySetInnerHTML={{ __html: getFullText() }} />
                <span className='fragment-mark'>{item.comparisonArr.length}</span>
              </p>
            )
          } else {
            return (
              <p className='western' key={item.element_number}>
                {item.full_text}
              </p>
            )
          }
        })}
      </div>
    </div>
  )
}
