import { API } from 'src/api'
import { parseCollections } from 'src/common/utils'
import { mainPageErrorMessage } from 'src/common/notifications'

import {
  REMOVE_UPLOADED_DOC,
  SET_COLLECTIONS,
  SET_CURRENT_DOC_NAME,
  SET_CURRENT_OPTION,
  SET_CURRENT_OPTION_NAME,
  SET_UPLOADED_DOC,
} from './uploadSteps-constants'

export const setCollections = payload => ({
  type: SET_COLLECTIONS,
  payload,
})
export const setCurrentOptionName = payload => ({
  type: SET_CURRENT_OPTION_NAME,
  payload,
})
export const setCurrentOption = payload => ({
  type: SET_CURRENT_OPTION,
  payload,
})
export const removeUploadedDoc = () => ({
  type: REMOVE_UPLOADED_DOC,
})
export const setUploadedDoc = payload => ({
  type: SET_UPLOADED_DOC,
  payload,
})
export const setCurrentDocName = payload => ({
  type: SET_CURRENT_DOC_NAME,
  payload,
})

export const fetchCollections = () => {
  return async dispatch => {
    try {
      const collectionsResponse = await API.getCollections()
      const collections = parseCollections(collectionsResponse.data)
      dispatch(setCollections(collections))
    } catch (err) {
      console.error(err)
      mainPageErrorMessage(err, 'Ошибка загрузки коллекций')
    }
  }
}
