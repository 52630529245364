import { notification } from 'antd'
import React from 'react'

export const errorMessage = (error, message) => {
  if (error?.response?.status === 401) return null
  notification.error({
    message: (
      <div>
        {message}
        <br />
        <a href='/'>Перейти на главную</a>
      </div>
    ),
  })
}

export const mainPageErrorMessage = (error, message) => {
  if (error?.response?.status === 401) return null
  notification.error({
    message: <div>{message}</div>,
  })
}
