import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import Header from './components/Header'
import {
  AuthPage,
  MainPage,
  PassRecovery,
  RegisterPage,
  ResultsPage,
  SimilarDocs,
  UserProfilePage,
} from './pages'
import PrivateRoute from './modules/router/PrivateRoute'

const App = () => {
  return (
    <div>
      <Header />
      <Switch>
        <Route exact path='/login' component={AuthPage} />
        <Route exact path='/confirm/:registerToken' component={AuthPage} />
        <Route exact path='/register' component={RegisterPage} />
        <PrivateRoute exact path='/' component={MainPage} />
        <PrivateRoute exact path='/results/:task_id/' component={ResultsPage} />
        <PrivateRoute exact path='/similar-documents/:similarDocId' component={SimilarDocs} />
        <PrivateRoute exact path='/pass-recovery' component={PassRecovery} />
        <PrivateRoute exact path='/profile' component={UserProfilePage} />
        <Route exact path='*' render={() => <Redirect to='/' />} />
      </Switch>
    </div>
  )
}

export default App
