import { API } from 'src/api'
import { mainPageErrorMessage } from 'src/common/notifications'

import {
  SET_CHECK_PROGRESS,
  SET_CHECKED_DOCS,
  SET_DOC_STATUS,
  SET_CURRENT_PAGE,
  SET_SOCKETS,
  SET_NEW_DOCS,
} from './docsList-constants'

const prepareDocsListItem = uploadedDocResponse => {
  const {
    collection_name,
    document_name,
    document_guid,
    task_created,
    task_guid,
    task_status,
  } = uploadedDocResponse
  return [
    {
      collection: {
        name: collection_name,
      },
      name: document_name,
      guid: document_guid,
      task: {
        created: task_created,
        guid: task_guid,
        status: task_status,
      },
    },
  ]
}

export const setSockets = payload => ({
  type: SET_SOCKETS,
  payload,
})
export const setCurrentPage = payload => ({
  type: SET_CURRENT_PAGE,
  payload,
})
export const setCheckedDocs = payload => ({
  type: SET_CHECKED_DOCS,
  payload,
})
export const setNewDocs = payload => ({
  type: SET_NEW_DOCS,
  payload,
})
export const setCheckProgress = (guid, progress) => ({
  type: SET_CHECK_PROGRESS,
  payload: { guid, progress },
})
export const setDocStatus = (guid, status) => ({
  type: SET_DOC_STATUS,
  payload: { guid, status },
})

export const getCheckedDocs = (pageSize, page) => {
  return async dispatch => {
    try {
      const checkedDocsResponse = await API.getCheckedDocs(pageSize, page)
      const checkedDocsObject = checkedDocsResponse.data
      dispatch(setCheckedDocs(checkedDocsObject))
    } catch (err) {
      console.error(err)
      mainPageErrorMessage(err, 'Ошибка при загрузке проверяемых документов')
    }
  }
}

export const sendNewDocs = (source_file, collectionName, name, setIsSuccessLoading) => {
  return async dispatch => {
    try {
      const sourceFileResponse = await API.sendNewDoc(source_file, collectionName, name)
      dispatch(setNewDocs(prepareDocsListItem(sourceFileResponse.data)))
      setIsSuccessLoading(true)
    } catch (err) {
      console.error(err)
      mainPageErrorMessage(err, 'Ошибка загрузки документа')
    }
  }
}
