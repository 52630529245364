import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, Link } from 'react-router-dom'
import { Spin, Popover, Button, Tag } from 'antd'
import { fetchComparisonResults, setTaskId } from 'src/modules/reducers/mainReducer/main-actions'
import {
  setActiveElement,
  setFragmentsScroll,
  setFilters,
  setFirstSortLevel,
} from 'src/modules/reducers/cashedResultsReducer/cashedResults-actions'
import { setDocumentStructureFilters } from 'src/modules/reducers/cashedResultsReducer/cashedResults-actions'
import { SvgIcon } from 'src/components/SvgIcon'
import {
  mainState,
  similarFragmentsSelector,
  structureElementsSelector,
  filteredSimilarFragmentsSelector,
  numberOfMatchedFragmentsSelector,
  fragmentPercentsSelector,
  currentDocMetaInfo,
  documentStructureSelector,
  resultPageFilterSelector,
  similarFragmentsOrderedKinds,
  documentStructureFilters,
  resultPageFilters,
  cashedResults,
} from 'src/modules/selectors'
// import SourceControls from 'src/components/SourceControls'
// import { useSort } from 'src/common'
import { DrawerFilter } from 'src/components/Drawer'

import { useDownloadFile } from './hooks'
import { Annotation, MPParagraphs, PopoverContent, Ruler, Source } from './components'
import { Filters } from './components/Filters'

import './index.styl'

const ResultsPage = () => {
  const mainPageRoot = useRef(null)
  const { task_id } = useSelector(mainState)
  const structureElements = useSelector(structureElementsSelector)
  const similarFragments = useSelector(similarFragmentsSelector)
  const filteredSimilarFragments = useSelector(filteredSimilarFragmentsSelector)
  const numberOfMatchedFragments = useSelector(numberOfMatchedFragmentsSelector)
  const fragmentPercents = useSelector(fragmentPercentsSelector)
  const documentStructure = useSelector(documentStructureSelector)
  const { name } = useSelector(currentDocMetaInfo)
  const [showAnnotation, setShowAnnotation] = useState(false)
  const [tags, setTags] = useState([])
  const [filtersVisible, setFiltersVisible] = useState(false)
  const [applyFilters, setApplyFilters] = useState(false)
  const [formClear, setFormClear] = useState(false)
  const resultPageFilter = useSelector(resultPageFilterSelector)
  const documentKinds = useSelector(similarFragmentsOrderedKinds)
  const filteredDocument = useSelector(documentStructureFilters)
  const filterValues = useSelector(resultPageFilters)
  const { firstSortLevelBtn } = useSelector(cashedResults)
  const [tagKey, setTagKey] = useState([])

  const { isFullCitation } = filteredDocument

  useEffect(() => {
    const pageFilterTags = resultPageFilter.filter(item => {
      if (Array.isArray(item.value) && item.value.length) return true
      return !item.value.endsWith('null')
    })
    setTags(pageFilterTags)
  }, [resultPageFilter, isFullCitation, filterValues])

  // Старая фильтрация левого столбца
  // const { isFullCitation, isProcessedWording, isUniqWording } = filteredDocument

  // const {
  //   sortField,
  //   sortOrder,
  //   jakkarDistanceFilter,
  //   jakkarMinDistanceFilter,
  //   cosineDistanceFilter,
  // } = fragments

  const [isFragmentLoading, setIsFragmentLoading] = useState(false)
  const dispatch = useDispatch()
  const params = useParams()
  const downloadFile = useDownloadFile()

  const fetchFragmentsArray = useCallback(async () => {
    structureElements.length && setIsFragmentLoading(true)
    await dispatch(fetchComparisonResults(params.task_id))
    setIsFragmentLoading(false)
  }, [dispatch, structureElements.length, params.task_id])

  useEffect(() => {
    !task_id && dispatch(setTaskId(params.task_id))
    if (task_id && task_id !== params.task_id) {
      fetchFragmentsArray()
      dispatch(setFragmentsScroll(0))
      dispatch(setActiveElement(null))
    }
  }, [dispatch, params.task_id, task_id, fetchFragmentsArray])

  useEffect(() => {
    !structureElements.length && fetchFragmentsArray()
  }, [structureElements.length, fetchFragmentsArray])

  const dividerMouseDown = () => {
    setIsFragmentLoading(true)
  }

  const dividerMouseUp = () => {
    setIsFragmentLoading(false)
    removeDividerListeners()
  }

  const dividerMouseMove = ({ pageX }) => {
    mainPageRoot.current.style.gridTemplateColumns = `${pageX - 58}px 16px auto`
  }

  const removeDividerListeners = () => {
    window.removeEventListener('mousemove', dividerMouseMove)
    window.removeEventListener('mousedown', dividerMouseDown)
    window.removeEventListener('mouseup', dividerMouseUp)
  }

  const handleDividerMouseDown = () => {
    window.addEventListener('mousemove', dividerMouseMove)
    window.addEventListener('mousedown', dividerMouseDown)
    window.addEventListener('mouseup', dividerMouseUp)
  }

  const handleTagClose = (key, removedTagValue) => e => {
    e.preventDefault()
    setTagKey([key, removedTagValue])

    const filterValue = () => {
      if (key === 'similarFragmentsDocKinds') {
        return filterValues[key].filter(item => item !== removedTagValue)
      }
      if (key === 'isFullCitation') {
        dispatch(setDocumentStructureFilters('isFullCitation', false))
        return null
      }
      if (key === 'rangePicker') {
        return null
      }

      return [0, 100]
    }

    const updateFilters = {
      ...filterValues,
      [key]: filterValue(),
    }

    dispatch(setFilters(updateFilters))
    const updatedTagList = tags.filter(({ value }) => value !== removedTagValue)
    setTags(updatedTagList)
  }

  const handleClearAllTags = () => {
    setTags([])
    setFormClear(!formClear)
  }

  const handleShowAnnotation = () => {
    setShowAnnotation(true)
  }

  const handleFiltersClose = () => {
    setFiltersVisible(false)
  }

  const handleFiltersApply = () => {
    setApplyFilters(!applyFilters)
  }

  const handleFiltersClear = () => {
    setFormClear(!formClear)
    setTags([])
  }

  const handleDrawerShow = () => {
    setFiltersVisible(true)
  }

  const getClassName = value => {
    if (filterValues.firstSortLevel === value) {
      return firstSortLevelBtn === 'desc' ? 'arrow-rotate' : ''
    }

    return null
  }

  return (
    <div className='MainPage-root' ref={mainPageRoot}>
      <div className='title-row whole-row'>
        <Link className='arrow-back' to='/'>
          <SvgIcon className='icon-left-arrow' iconName='arrow-right' />
        </Link>
        <h3 className='doc-name title'>
          {isFragmentLoading || !documentStructure.length ? 'Загрузка...' : name}
        </h3>
        <Button
          className='menu-button'
          icon={<SvgIcon className='filter-icon' iconName='download' />}
          onClick={downloadFile}
        >
          Документ
        </Button>
        <Button
          className='menu-button'
          icon={<SvgIcon className='filter-icon' iconName='annotation' />}
          onClick={handleShowAnnotation}
          disabled={!numberOfMatchedFragments || isFragmentLoading}
        >
          Аннотация
        </Button>
        <Button
          className='menu-button'
          onClick={handleDrawerShow}
          icon={<SvgIcon className='filter-icon' iconName='filter' />}
          disabled={!numberOfMatchedFragments || isFragmentLoading}
        >
          Фильтры
        </Button>
      </div>
      <div className='whole-row tag-list'>
        {tags.map(({ key, value }) => (
          <Tag key={value} onClose={handleTagClose(key, value)} closable>
            {value}
          </Tag>
        ))}
        {Boolean(tags.length) && (
          <Button type='link-secondary' onClick={handleClearAllTags}>
            Очистить всё
          </Button>
        )}
      </div>
      <Spin wrapperClassName='Mainpage-paragraphs-spin' spinning={isFragmentLoading} size='large'>
        <div className='Mainpage-header'>
          <span className='fragments-info title'>
            {`Найдено фрагментов: ${numberOfMatchedFragments} из ${structureElements.length} (${fragmentPercents}%)`}
          </span>
        </div>
        <Ruler isFragmentLoading={isFragmentLoading} />
        <MPParagraphs isLoading={isFragmentLoading} documentStructure={documentStructure} />
      </Spin>
      <div className='results-divider' onMouseDown={handleDividerMouseDown}>
        <SvgIcon className='resize-icon' iconName='resize' />
      </div>
      <div className='Mainpage-paragraphs Mainpage-paragraphs-source'>
        <div className='Mainpage-header'>
          <div className='Mainpage-header-title'>
            <h3 className='Mainpage-source-title title'>Схожие фрагменты</h3>
            <Popover
              overlayClassName='source-popover'
              content={<PopoverContent />}
              placement='rightTop'
            >
              <div className='Mainpage-source-icon'>
                <SvgIcon className='Mainpage-source-icon' iconName='question-mark' />
              </div>
            </Popover>
          </div>
          <div className='sort-icons-container'>
            <div
              className={`sort-icon ${
                filterValues.firstSortLevel === 'jakkar_distance' ? 'icon-active' : ''
              }`}
              onClick={() => dispatch(setFirstSortLevel('jakkar_distance'))}
            >
              <SvgIcon iconName='resize' />
              <p>Ж</p>
              <SvgIcon className={getClassName('jakkar_distance')} iconName='arrow-button' />
            </div>
            <div
              className={`sort-icon ${
                filterValues.firstSortLevel === 'jakkar_min_distance' ? 'icon-active' : ''
              }`}
              onClick={() => dispatch(setFirstSortLevel('jakkar_min_distance'))}
            >
              <SvgIcon iconName='resize' />
              <p>М</p>
              <SvgIcon className={getClassName('jakkar_min_distance')} iconName='arrow-button' />
            </div>
            <div
              className={`sort-icon ${
                filterValues.firstSortLevel === 'cosine_distance' ? 'icon-active' : ''
              }`}
              onClick={() => dispatch(setFirstSortLevel('cosine_distance'))}
            >
              <SvgIcon iconName='resize' />
              <p>К</p>
              <SvgIcon className={getClassName('cosine_distance')} iconName='arrow-button' />
            </div>
          </div>
        </div>
        <Source
          similarFragments={similarFragments}
          filteredSimilarFragments={filteredSimilarFragments}
          isFragmentLoading={isFragmentLoading}
          documentStructure={documentStructure}
          numberOfMatchedFragments={numberOfMatchedFragments}
        />
      </div>

      <Annotation
        visible={showAnnotation}
        setVisible={setShowAnnotation}
        structureElementsLength={structureElements.length}
      />

      <DrawerFilter
        visible={filtersVisible}
        onApply={handleFiltersApply}
        onClose={handleFiltersClose}
        onClear={handleFiltersClear}
      >
        <Filters
          isClear={formClear}
          tagKey={tagKey}
          documentKinds={documentKinds}
          similarFragmentsNumber={filteredSimilarFragments.length}
          filterValues={filterValues}
        />
      </DrawerFilter>
    </div>
  )
}

export default ResultsPage
