import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { isAuthenticated } from 'src/modules/selectors'

import { SvgIcon } from '../SvgIcon'
import UserDropdown from '../UserDropdown'

import './index.styl'

const Header = () => {
  const isAuth = useSelector(isAuthenticated)

  return (
    <header className='header-wrapper'>
      <div className='header-title-wrapper'>
        <Link to='/'>
          <SvgIcon className='header-icon' iconName='main-app-logo' />
        </Link>
        <h1 className='header-title'>Экспертиза НПА</h1>
      </div>

      {isAuth ? (
        <div className='header-container'>
          <div className='header-buttons-wrapper'>
            <NavLink type='primary' className='header-button' to='/'>
              <span className='header-button-span'>Документы</span>
            </NavLink>
          </div>
          {/* <Link className='header-button' to='/'>
            <SvgIcon className='header-button-icon' iconName='add' />
            <span className='header-button-span'>Проверить документ</span>
          </Link> */}
          {/* <HeaderDropdown /> */}
          <div className='header-container'>
            <UserDropdown />
          </div>
        </div>
      ) : (
        ''
      )}
    </header>
  )
}

export default Header
