import React from 'react'
const requireAll = requireContext => requireContext.keys().map(requireContext)
const req = require.context('src/assets/images/svg-icons-html', false, /\.svg$/)
requireAll(req)

export const SvgIcon = ({ iconName, className }) => {
  const svgClass = className ? `icon-default ${className}` : 'icon-default'
  const icon = iconName ? `icon-${iconName}` : ''
  const name = iconName ? `#icon-${iconName}` : ''

  return (
    <svg className={svgClass} aria-hidden='true'>
      <use className={`icon-use ${icon}`} xlinkHref={name} href={name}>
        {' '}
      </use>
    </svg>
  )
}
