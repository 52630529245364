import React from 'react'
import { Button, Form, Input, message } from 'antd'
import Dialog from 'src/components/Dialog'

import './PassRecoveryDialog.styl'

export const PassRecoveryDialog = ({ visible, setVisible }) => {
  const onFinish = () => {
    // console.log('Success:', values)
    setTimeout(() => {
      setVisible(false)
      message.success('Ссылка для восстановления пароля была отправлена на электронную почту')
    }, 300)
  }

  const onFinishFailed = () => {
    // console.log('Failed:', errorInfo)
  }

  const DialogBody = (
    <>
      <p className='pass-recovery-text'>
        Введите адрес электронной почты, мы отправим вам ссылку для восстановления пароля
      </p>

      <Form
        name='pass-recovery'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        colon={false}
        requiredMark={false}
      >
        <Form.Item
          name='email'
          label='E-mail'
          rules={[
            {
              type: 'email',
              message: 'Пожалуйста, укажите корректный E-mail.',
            },
            {
              required: true,
              message: 'Пожалуйста, укажите свой E-mail.',
            },
          ]}
        >
          <Input placeholder='Введите e-mail' />
        </Form.Item>

        <Form.Item>
          <Button type='primary pass-recovery-btn' htmlType='submit'>
            Восстановить пароль
          </Button>
        </Form.Item>
      </Form>
    </>
  )

  return (
    <Dialog title='Восстановить пароль' visible={visible} setVisible={setVisible}>
      {DialogBody}
    </Dialog>
  )
}
