import { LOGIN, LOGOUT, SET_USER } from './session-constants'

export const authState = {
  token: '',
  user: {
    login: '',
  },
}

export const sessionReducer = (state = authState, action) => {
  switch (action.type) {
    case SET_USER: {
      return {
        ...state,
        user: action.payload,
      }
    }
    case LOGIN: {
      return {
        ...state,
        token: action.payload,
      }
    }
    case LOGOUT: {
      return {
        ...state,
        token: '',
        user: {
          login: '',
        },
      }
    }
    default:
      return state
  }
}
