import React from 'react'
import { Radio } from 'antd'

import './ModalTitle.styl'

export const ModalTitle = ({ radioValue, setRadioValue }) => (
  <div className='annotation-radio-wrapper'>
    <span className='text-small'>Размер аннотации</span>
    <Radio.Group onChange={e => setRadioValue(e.target.value)} value={radioValue}>
      <Radio value={15}>15 слов</Radio>
      <Radio value={300}>300 слов</Radio>
      <Radio value={1500}>1500 слов</Radio>
    </Radio.Group>
  </div>
)
