import React from 'react'

const PopoverContent = () => (
  <div className='popover-wrapper'>
    <span>Проверка положений НПА на схожесть осуществляется по трем метрикам:</span>
    <ol>
      <li>коэффициент Жаккара,</li>
      <li>метод минимума,</li>
      <li>метод косинусного расстояния.</li>
    </ol>
  </div>
)

export default React.memo(PopoverContent)
