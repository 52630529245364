import moment from 'moment'

export const validatePass = pass => {
  if (!pass) return 'valid'
  const messageInit = 'Пароль должен содержать'
  let message = messageInit
  const validationArray = [
    {
      regexp: /[a-z]/,
      message: ' строчные латинские буквы,',
    },
    {
      regexp: /[A-Z]/,
      message: ' заглавные латинские буквы,',
    },
    {
      regexp: /\d/,
      message: ' цифры,',
    },
    {
      regexp: /\W/,
      message: ' символы,',
    },
  ]
  if (pass.length < 8) message += ' минимум 8 знаков,'
  if (pass.length > 256) message += ' не более 256 символов,'
  validationArray.forEach(item => {
    if (!item.regexp.test(pass)) message += item.message
  })
  if (message === messageInit) return 'valid'
  else return message.slice(0, -1)
}

export const validateName = () => ({
  validator(_, value) {
    if (!value) return Promise.resolve()

    const trimmedValue = value.trim()

    if (trimmedValue.length < 2) {
      return Promise.reject('Минимальная длина строки - 2 символа')
    }
    if (trimmedValue.length > 128) {
      return Promise.reject('Максимальная длина строки - 128 символов')
    }
    return Promise.resolve()
  },
})

/**
 * @param {object} userNameFields - { firstname, lastname }
 * @param {(object) => void} setFieldValue - function, that set trimmed values
 * @return {void}
 */
export const setTrimmedUserNameFields = ({ firstname, lastname }, setFieldValue) => {
  firstname && setFieldValue({ firstname: firstname.trimStart() })
  lastname && setFieldValue({ lastname: lastname.trimStart() })
}

export const parseCollections = rawCollections => {
  const collectionsKindSet = new Set()
  rawCollections.forEach(item => collectionsKindSet.add(item.kind))
  return [...collectionsKindSet].map(item => ({
    value: item,
    label: item,
    children: rawCollections
      .filter(child => child.kind === item)
      .map(({ guid, name }) => ({ value: guid, label: name })),
  }))
}

export const formatDate = date => moment(date).format('DD.MM.YYYY H:mm')
