import { API } from 'src/api'
import { mainPageErrorMessage } from 'src/common/notifications'

import { LOGIN, LOGOUT, SET_USER } from './session-constants'

export const logIn = payload => ({
  type: LOGIN,
  payload,
})
export const logout = () => ({
  type: LOGOUT,
})
export const setUser = payload => ({
  type: SET_USER,
  payload,
})

export const fetchSession = (login, password) => {
  return async dispatch => {
    try {
      const { data: sessionResponse } = await API.singIn(login, password)
      dispatch(logIn(sessionResponse.token))
      dispatch(setUser(sessionResponse.user))
    } catch (err) {
      console.error(err)
      mainPageErrorMessage(err, 'Ошибка авторизации')
    }
  }
}
