import { scroller } from 'react-scroll'

export const scrollToElement = (containerId, elementNumberString) => {
  scroller.scrollTo(elementNumberString, {
    duration: 500,
    delay: 0,
    smooth: true,
    containerId,
  })
}
