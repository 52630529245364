import React, { useState } from 'react'
import { Button, Spin } from 'antd'
import { useDropzone } from 'react-dropzone'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentDocName } from 'src/modules/reducers/uploadStepsReducer/uploadSteps-actions'
import { sendNewDocs } from 'src/modules/reducers/docsListReducer/docsList-actions'
import { mainPageErrorMessage } from 'src/common/notifications'
import { uploadDocs } from 'src/modules/selectors'

import './index.styl'

const acceptedMimetype = '.txt, .doc, .docx'

const Dropzone = ({ setIsSuccessLoading }) => {
  const dispatch = useDispatch()
  const { currentOption, optionName, currentDocName } = useSelector(uploadDocs)
  const [isFetching, setIsFetching] = useState(false)

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    noClick: true,
    accept: acceptedMimetype,
    multiple: false,
    disabled: !currentOption.label,
    onDropRejected: fileArr => {
      const isToManyFiles = fileArr[0].errors.some(item => item.code === 'too-many-files')
      const isInvalidType = fileArr[0].errors.some(item => item.code === 'file-invalid-type')
      if (isToManyFiles) mainPageErrorMessage({}, 'Нельзя загружать более одного файла за раз')
      if (isInvalidType) mainPageErrorMessage({}, 'Неправильный формат документа')
    },
    onDropAccepted: async acceptedFiles => {
      const [currentDoc] = acceptedFiles
      const currentDocName = currentDoc.name.split('.').slice(0, -1).join('')

      setIsFetching(true)
      await dispatch(setCurrentDocName(currentDocName))
      await dispatch(sendNewDocs(currentDoc, optionName, currentDocName, setIsSuccessLoading))
      setIsFetching(false)
    },
  })

  const dropzoneFetchingMessage = (
    <div className='dropzone-fetching-message'>
      <Spin className='dropzone-spin' size='large' />
      <div className='dropzone-fetching-list'>
        <div className='dropzone-text text-small'>
          Для загрузки новых документов, пожалуйста, дождитесь окончания загрузки текущих
          документов:
        </div>
        <div className='dropzone-text text-small'>{`"${currentDocName}"`}</div>
      </div>
      <Button
        className='button-cancel button-cancel-dropzone'
        onClick={() => {
          alert('отмена операции')
        }}
      >
        Отменить
      </Button>
    </div>
  )

  const dropzoneMessage = isDragActive ? (
    <span className='dropzone-text'>Перетащите файл сюда ...</span>
  ) : currentOption.label ? (
    <>
      <span className='dropzone-text'>Перетащите сюда документ или нажмите</span>
      <span className='dropzone-text disabled-button' onClick={open}>
        загрузить
      </span>
    </>
  ) : (
    <>
      <span className='dropzone-text'>
        Выберите коллекцию, затем перетащите сюда документ или нажмите
      </span>
      <span className='dropzone-text disabled-button'>загрузить</span>
    </>
  )

  return (
    <div className='dropzone-wrapper'>
      <div
        {...getRootProps({
          className: `dropzone ${!currentOption.label ? 'disabled' : ''}`,
        })}
      >
        <input {...getInputProps()} />
        {isFetching ? dropzoneFetchingMessage : dropzoneMessage}
      </div>
    </div>
  )
}

export default Dropzone
