import { createSelector } from 'reselect'
import moment from 'moment'
import { omit } from 'lodash'

export const isAuthenticated = state => !!state.session.token
export const docsListObject = state => state.docsListObject
export const uploadDocs = state => state.uploadDocs
export const mainState = state => state.main
export const comparisonResults = state => state.main.comparison_results
export const currentDocMetaInfo = state => state.main.currentDocMetaInfo
export const userProfile = state => state.main.userProfile
export const filters = state => state.main.filters
export const sortLevelBtn = state => state.main.sortLevelBtn
export const resultPageFilters = state => state.cashedResults.filters
export const cashedResults = state => state.cashedResults
export const activeElement = state => state.cashedResults.activeElement
export const dateRangeFilter = state => state.cashedResults.dateRangeFilter
export const similarFragmentsSettings = state => state.cashedResults.similarFragments
export const similarFragmentsDocKinds = state => state.cashedResults.similarFragments.docKinds
export const similarFragmentsOrderedKinds = state =>
  state.cashedResults.similarFragments.orderedKinds
export const documentStructureFilters = state => state.cashedResults.documentStructureFilters
export const activeElementHighlightedFullText = state =>
  state.cashedResults.activeElementHighlightedFullText
export const similarFragmentsOrderedKindsArr = state =>
  state.cashedResults.similarFragments.orderedKinds

export const processingDocsListSelector = createSelector(docsListObject, docListObj =>
  docListObj.docsList.filter(item => item.task.status === 'processing'),
)

export const structureElementsSelector = createSelector(
  mainState,
  state => state.source_document_structure_elements?.structure_elements || [],
)

export const sortDocsListSelector = createSelector(
  [docsListObject, sortLevelBtn],
  (docsListObject, sortLevelBtn) => {
    const { docsList } = docsListObject

    if (docsList.length) {
      switch (sortLevelBtn) {
        case 'asc':
          return [...docsList].sort((a, b) => new Date(b.updated) - new Date(a.updated))
        case 'desc':
          return [...docsList].sort((a, b) => new Date(a.updated) - new Date(b.updated))
      }
    }
    return docsList
  },
)

export const filtersSelector = createSelector(filters, filters => {
  const result = Object.keys(filters).filter(
    item => !(Array.isArray(filters[item]) ? filters[item].length : filters[item]),
  )
  return omit(filters, result)
})

export const resultPageFilterSelector = createSelector(
  [resultPageFilters, documentStructureFilters],
  (filters, documentStructureFilters) => {
    const { isFullCitation } = documentStructureFilters
    const filtersArray = []

    const addFullCitationValueToFilters = () =>
      filtersArray.push({ key: 'isFullCitation', value: 'Только полное цитирование' })

    if (isFullCitation) addFullCitationValueToFilters()

    Object.keys(filters).forEach(key => {
      const sliderToString = arr => {
        if (Array.isArray(arr) && arr.length) {
          if (arr[0] !== 0 || arr[1] !== 100) {
            if (arr[0] === arr[1]) return arr[0]
            return filters[key].join('-')
          }
          return null
        }
        return arr?.toString()
      }

      switch (key) {
        case 'jakkarSlider':
          filtersArray.push({
            key,
            value: `Жаккар ${sliderToString(filters[key])}`,
          })
          break
        case 'minSlider':
          filtersArray.push({
            key,
            value: `Минимум ${sliderToString(filters[key])}`,
          })
          break
        case 'cosineSlider':
          filtersArray.push({
            key,
            value: `Косинус ${sliderToString(filters[key])}`,
          })
          break
        case 'rangePicker':
          filtersArray.push({
            key,
            value: `c ${filters[key]?.length ? filters[key].join(' по ') : null}`,
          })
          break
        case 'similarFragmentsDocKinds':
          filters[key].forEach(item => filtersArray.push({ key, value: item }))
          break
        default:
          return filtersArray
      }
    })
    return filtersArray
  },
)

export const similarFragmentsOrderedKindsMapSelector = createSelector(
  similarFragmentsOrderedKindsArr,
  orderedKindsArr => {
    const orderedKindsMap = new Map()
    orderedKindsArr.forEach((item, i) => orderedKindsMap.set(item, i))
    return orderedKindsMap
  },
)

export const similarFragmentsSelector = createSelector(
  [comparisonResults, activeElement],
  (comparisonResults, activeElement) => {
    return comparisonResults.filter(
      item => item.source_fragment_element_number === activeElement?.element_number,
    )
  },
)

export const filterSimilarFragmentsByDateSelector = createSelector(
  [similarFragmentsSelector, dateRangeFilter],
  (similarFragments, dateRange) => {
    if (!dateRange) return similarFragments
    return similarFragments.filter(item => {
      const currentDate = item.similar_document_date_of_sign
      return moment(currentDate).isBetween(dateRange[0], dateRange[1], undefined, '[]')
    })
  },
)

export const sortedSimilarFragmentsByKindsSelector = createSelector(
  [filterSimilarFragmentsByDateSelector, similarFragmentsOrderedKindsMapSelector],
  (similarFragments, orderedKindsMap) => {
    //Todo: remove when similar_document_kind will be Capitalize
    const upperCaseKindMap = new Map()
    orderedKindsMap.forEach((value, key) => {
      upperCaseKindMap.set(key.toUpperCase(), value)
    })

    return similarFragments.sort((a, b) => {
      const getCorrectKind = item => {
        if (item.similar_document_kind === null) return 1000
        return upperCaseKindMap.get(item.similar_document_kind)
      }
      return getCorrectKind(a) - getCorrectKind(b)
    })
  },
)

export const sortedSimilarFragmentsSelector = createSelector(
  [cashedResults, resultPageFilters, sortedSimilarFragmentsByKindsSelector],
  (cashedResults, filters, similarFragments) => {
    const { firstSortLevelBtn, secondSortLevelBtn, thirdSortLevelBtn } = cashedResults
    const { firstSortLevel, secondSortLevel, thirdSortLevel } = filters

    // add pag in the sortLevelBtn
    if (sortLevelBtn === 'pag') {
      return [...similarFragments]
    }

    const firstSortLevelFragments = [...similarFragments].sort((a, b) => {
      const sort = Math.round(b[firstSortLevel]) - Math.round(a[firstSortLevel])
      return firstSortLevelBtn === 'asc' ? sort : -sort
    })

    const getSecondSortLevelFragments = arr =>
      arr.sort((a, b) => {
        const firstSort = Math.round(b[firstSortLevel]) - Math.round(a[firstSortLevel])
        const secondSort = Math.round(b[secondSortLevel]) - Math.round(a[secondSortLevel])

        if (firstSort === 0) {
          return secondSortLevelBtn === 'asc' ? secondSort : -secondSort
        }

        return 0
      })

    const getThirdSortLevelFragments = arr =>
      [...arr].sort((a, b) => {
        const firstSort = Math.round(b[firstSortLevel]) - Math.round(a[firstSortLevel])
        const secondSort = Math.round(b[secondSortLevel]) - Math.round(a[secondSortLevel])
        const thirdSort = Math.round(b[thirdSortLevel]) - Math.round(a[thirdSortLevel])

        if (firstSort === 0 && secondSort === 0) {
          return thirdSortLevelBtn === 'asc' ? thirdSort : -thirdSort
        }

        return 0
      })

    return getThirdSortLevelFragments(getSecondSortLevelFragments(firstSortLevelFragments))
  },
)

export const filteredSimilarFragmentsSelector = createSelector(
  [resultPageFilters, sortedSimilarFragmentsSelector],
  (filters, sortedSimilarFragments) => {
    const { jakkarSlider, minSlider, cosineSlider, similarFragmentsDocKinds, rangePicker } = filters

    const isDataFilter = () => {
      const dataFilter = sortedSimilarFragments.filter(item => {
        const currentDate = item.similar_document_date_of_sign
        if (rangePicker?.length)
          return moment(currentDate).isBetween(rangePicker[0], rangePicker[1])
        return true
      })

      return Boolean(dataFilter.length)
    }

    //Todo: remove when similar_document_kind will be Capitalize
    const upperCaseDocKinds =
      similarFragmentsDocKinds && similarFragmentsDocKinds.map(item => item.toUpperCase())

    return [...sortedSimilarFragments].filter(item => {
      const docKindsFilter = upperCaseDocKinds?.length
        ? upperCaseDocKinds.includes(item.similar_document_kind)
        : item

      const docKindFilterCompare = (item, filter) => {
        const itemIsRounded = Math.round(item)
        return filter ? filter[0] <= itemIsRounded && filter[1] >= itemIsRounded : true
      }

      return (
        isDataFilter() &&
        docKindsFilter &&
        docKindFilterCompare(item.jakkar_distance, jakkarSlider) &&
        docKindFilterCompare(item.jakkar_min_distance, minSlider) &&
        docKindFilterCompare(item.cosine_distance, cosineSlider)
      )
    })
  },
)

export const documentStructureSelector = createSelector(
  [structureElementsSelector, comparisonResults],
  (structureElements, comparisonResults) => {
    return structureElements.map(item => {
      const comparisonArr = comparisonResults.filter(
        ({ source_fragment_element_number }) =>
          source_fragment_element_number === item.element_number,
      )
      if (comparisonArr.length) {
        const fullCitation = comparisonArr.some(item => item.jakkar_distance === 100)
        return {
          ...item,
          comparisonArr,
          fullCitation,
        }
      } else {
        return item
      }
    })
  },
)

export const documentStructureFilteredSelector = createSelector(
  [documentStructureSelector, documentStructureFilters],
  (documentStructure, filters) => {
    const { isFullCitation, isProcessedWording, isUniqWording } = filters
    const isProcessedWordingItem = comparisonArr => {
      return comparisonArr.some(
        ({ jakkar_distance, jakkar_min_distance, cosine_distance }) =>
          jakkar_distance >= 50 &&
          jakkar_distance <= 80 &&
          jakkar_min_distance >= 80 &&
          jakkar_min_distance <= 90 &&
          cosine_distance >= 95 &&
          cosine_distance <= 100,
      )
    }
    if (!isFullCitation && !isProcessedWording && !isUniqWording) return documentStructure
    return documentStructure.filter(({ fullCitation, comparisonArr }) => {
      if (isFullCitation && fullCitation) return true
      if (isProcessedWording && comparisonArr && isProcessedWordingItem(comparisonArr)) return true
      return !fullCitation && !comparisonArr && isUniqWording
    })
  },
)

export const matchedFragmentsSelector = createSelector(
  [documentStructureSelector],
  documentStructure => {
    return documentStructure.filter(item => !!item.comparisonArr)
  },
)

export const numberOfMatchedFragmentsSelector = createSelector(
  [documentStructureSelector],
  documentStructure => {
    return documentStructure.filter(item => !!item.comparisonArr).length
  },
)

export const fragmentPercentsSelector = createSelector(
  [structureElementsSelector, numberOfMatchedFragmentsSelector],
  (structureElements, numberOfMatchedFragments) => {
    if (!structureElements.length) return 0
    return ((100 * numberOfMatchedFragments) / structureElements.length).toFixed()
  },
)

export const comparisonResultsUniqDocNamesSelector = createSelector(
  [comparisonResults],
  comparisonResults => {
    const comparisonResultsDocNamesArr = comparisonResults.map(item => item.similar_document_name)
    const uniqDocNamesSet = new Set(comparisonResultsDocNamesArr)
    return [...uniqDocNamesSet]
  },
)
