import { instance } from '../modules/store/interceptors'

export const API = {
  getDocumentStructure(id) {
    return instance.get(`/comparison/document_structure/${id}`)
  },
  getComparisonResults(id) {
    return instance.get(`/comparison/tasks/${id}/result`)
  },
  getCollections() {
    return instance.get('/comparison/collections/')
  },
  singIn(login, password) {
    const headers = {
      'Content-Type': 'multipart/form-data',
    }
    const formData = new FormData()
    formData.append('username', login)
    formData.append('password', password)
    return instance.post(`auth/`, formData, { headers })
  },
  register(registerData) {
    return instance.post(`register/`, registerData)
  },
  registerConfirm(token) {
    return instance.post(`register/confirm/`, { token })
  },
  sendNewDoc(source_file, collection, name) {
    const headers = {
      'Content-Type': 'multipart/form-data',
    }
    const formData = new FormData()
    formData.append('file', source_file)
    formData.append('collection_id', collection)
    formData.append('name', name)

    return instance.post('comparison/', formData, { headers })
  },
  getCheckedDocs(page_size, page) {
    return instance.get('/comparison/history/', {
      params: { page_size, page, ordering: undefined },
    })
  },
  downloadFile(taskId) {
    return instance.get(`comparison/tasks/${taskId}/result`, {
      params: { export: 'xlsx' },
      responseType: 'blob',
    })
  },
}
