import { useDispatch, useSelector } from 'react-redux'
import { docsListObject, processingDocsListSelector } from 'src/modules/selectors'
import { useEffect } from 'react'
import {
  setCheckProgress,
  setDocStatus,
  setSockets,
} from 'src/modules/reducers/docsListReducer/docsList-actions'

import { mainPageErrorMessage } from '../notifications'

const getWebSocketPath = taskId => {
  const location = window.location
  let socketUrl

  if (location.protocol === 'https:') {
    socketUrl = 'wss:'
  } else {
    socketUrl = 'ws:'
  }
  socketUrl += '//' + location.host
  socketUrl += `${location.pathname}ws/tasks/${taskId}/`
  return socketUrl
}

export const useSockets = () => {
  const { openSocketsID } = useSelector(docsListObject)
  const processingDocsList = useSelector(processingDocsListSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    const onMessage = (event, docItem) => {
      const data = JSON.parse(event?.data || '')
      const { guid, progress, status } = data
      if (docItem.task.status !== status) {
        dispatch(setDocStatus(guid, status))
      }
      if (status === 'processing' && docItem.task.progress !== progress) {
        dispatch(setCheckProgress(guid, progress))
      }
    }

    const onError = (error, docItem) => {
      console.error(error)
      mainPageErrorMessage(error, 'Ошибка при получении прогресса документа')
      dispatch(setDocStatus(docItem.task.guid, 'processing'))
    }

    if (processingDocsList.length) {
      const newSocketSet = new Set()

      processingDocsList.forEach(docItem => {
        if (openSocketsID.has(docItem.task.guid)) return null
        const socket = new WebSocket(getWebSocketPath(docItem.task.guid))
        newSocketSet.add(docItem.task.guid)

        socket.onmessage = event => onMessage(event, docItem)
        socket.onerror = error => onError(error, docItem)
      })

      const updatedSocketSet = new Set([...newSocketSet, ...openSocketsID])
      newSocketSet.size > 0 && dispatch(setSockets(updatedSocketSet))
    }
  }, [dispatch, processingDocsList, openSocketsID])
}
