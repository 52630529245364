import {
  SET_CURRENT_PAGE,
  SET_CHECKED_DOCS,
  SET_CHECK_PROGRESS,
  SET_DOC_STATUS,
  SET_SOCKETS,
  SET_NEW_DOCS,
} from './docsList-constants'

const initialState = {
  currentPage: 1,
  pageSize: 10,
  totalResults: 0,
  openSocketsID: new Set(),
  docsList: [],
}

export const docsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SOCKETS: {
      return {
        ...state,
        openSocketsID: action.payload,
      }
    }
    case SET_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      }
    }
    case SET_CHECKED_DOCS: {
      const { count, results } = action.payload
      return {
        ...state,
        totalResults: count,
        docsList: [...state.docsList, ...results],
      }
    }
    case SET_NEW_DOCS: {
      return {
        ...state,
        docsList: [...action.payload, ...state.docsList],
      }
    }
    case SET_CHECK_PROGRESS: {
      return {
        ...state,
        docsList: state.docsList.map(item => {
          if (action.payload.guid === item.task.guid) {
            item.task.progress = action.payload.progress
          }
          return item
        }),
      }
    }
    case SET_DOC_STATUS: {
      return {
        ...state,
        docsList: state.docsList.map(item => {
          if (action.payload.guid === item.task.guid) {
            item.task.status = action.payload.status
          }
          return item
        }),
      }
    }
    default:
      return state
  }
}
