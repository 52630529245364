import React from 'react'
import { Modal } from 'antd'

import { SvgIcon } from '../SvgIcon'

import './index.styl'

const Dialog = ({ visible, setVisible, title, titleBody, height, hideButtonClose, children }) => {
  const modalTitle = (
    <div>
      <h3>{title}</h3>
      {titleBody ? titleBody : ''}
    </div>
  )

  return (
    <>
      <Modal
        width={850}
        title={modalTitle}
        visible={visible}
        footer={null}
        bodyStyle={{ height: `${height || 'auto'}` }}
        onCancel={() => setVisible(false)}
        closeIcon={<SvgIcon className='sign-close' iconName='close' />}
        closable={!hideButtonClose}
      >
        <div className='annotation-content-wrapper'>{children}</div>
      </Modal>
    </>
  )
}

export default Dialog
