import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Skeleton } from 'antd'
import { cashedResults, documentStructureFilteredSelector } from 'src/modules/selectors'

import { DocumentContentElement } from './components'

import './index.styl'

const MPParagraphs = ({ isLoading, documentStructure }) => {
  const { activeElement } = useSelector(cashedResults)
  const filteredOriginalFragments = useSelector(documentStructureFilteredSelector)

  const documentContent = useMemo(() => {
    if (isLoading) {
      return <DocumentContentElement activeElement={{}} documentStructure={[]} />
    }
    return filteredOriginalFragments.length ? (
      <DocumentContentElement
        activeElement={activeElement}
        documentStructure={filteredOriginalFragments}
      />
    ) : (
      <div style={{ marginLeft: 25 }}>
        Не найдено фрагментов, соответствующих выбранным фильтрам.
      </div>
    )
  }, [activeElement, filteredOriginalFragments, isLoading])

  return (
    <div className='Paragraphs-wrapper'>
      {!documentStructure.length ? <Skeleton active /> : <>{documentContent}</>}
    </div>
  )
}

export default MPParagraphs
