import { API } from 'src/api'
import { errorMessage } from 'src/common/notifications'
import { saveAs } from 'file-saver'
import { useSelector } from 'react-redux'
import { currentDocMetaInfo, mainState } from 'src/modules/selectors'

import { createXlsxFileNameFromMetaInfo } from './helpers'

export const useDownloadFile = () => {
  const currentDocMeta = useSelector(currentDocMetaInfo)
  const { task_id } = useSelector(mainState)

  return async () => {
    try {
      const response = await API.downloadFile(task_id)
      const fileName = createXlsxFileNameFromMetaInfo(currentDocMeta)
      saveAs(response.data, fileName)
    } catch (err) {
      console.error(err)
      errorMessage(err, 'Ошибка загрузки документа')
    }
  }
}
