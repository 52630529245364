export const SET_TASK_ID = 'SET_TASK_ID'
export const SET_SOURCE_DOCUMENT_STRUCTURE_ELEMENTS = 'SET_SOURCE_DOCUMENT_STRUCTURE_ELEMENTS'
export const SET_COMPARISON_RESULTS = 'SET_COMPARISON_RESULTS'
export const SET_SIMILAR_DOCS_STRUCTURE = 'SET_SIMILAR_DOCS_STRUCTURE'
export const SET_HISTORY_DOCS = 'SET_HISTORY_DOCS'
export const SET_RESULTS_ANCHORS = 'SET_RESULTS_ANCHORS'
export const SET_RESULTS_ROOT_ELEMENT = 'SET_RESULTS_ROOT_ELEMENT'
export const SET_CURRENT_DOM_META_INFO = 'SET_CURRENT_DOM_META_INFO'
export const SET_FILTERS = 'SET_FILTERS'
export const MERGE_USER_PROFILE = 'MERGE_USER_PROFILE'
export const SORT_LEVEL_BTN = 'SORT_LEVEL_BTN'
