import { API } from 'src/api'
import { errorMessage } from 'src/common/notifications'

import {
  SET_TASK_ID,
  SET_SOURCE_DOCUMENT_STRUCTURE_ELEMENTS,
  SET_COMPARISON_RESULTS,
  SET_SIMILAR_DOCS_STRUCTURE,
  SET_HISTORY_DOCS,
  SET_RESULTS_ANCHORS,
  SET_RESULTS_ROOT_ELEMENT,
  SET_CURRENT_DOM_META_INFO,
  SET_FILTERS,
  MERGE_USER_PROFILE,
  SORT_LEVEL_BTN,
} from './main-constants'

export const setSortLevelBtn = payload => ({
  type: SORT_LEVEL_BTN,
  payload,
})

export const setFilters = payload => ({
  type: SET_FILTERS,
  payload,
})

export const setHistoryDocs = payload => ({
  type: SET_HISTORY_DOCS,
  payload,
})
export const setTaskId = payload => ({
  type: SET_TASK_ID,
  payload,
})
export const setSourceDocumentStructureElements = payload => ({
  type: SET_SOURCE_DOCUMENT_STRUCTURE_ELEMENTS,
  payload,
})
export const setComparisonResults = (results, taskId) => ({
  type: SET_COMPARISON_RESULTS,
  payload: { results, taskId },
})
export const setSimilarDocsStructure = payload => ({
  type: SET_SIMILAR_DOCS_STRUCTURE,
  payload,
})
export const setResultsAnchors = payload => ({
  type: SET_RESULTS_ANCHORS,
  payload,
})
export const setResultsRootElem = payload => ({
  type: SET_RESULTS_ROOT_ELEMENT,
  payload,
})
export const setCurrentDocMetaInfo = payload => ({
  type: SET_CURRENT_DOM_META_INFO,
  payload,
})
export const mergeUserProfile = payload => ({
  type: MERGE_USER_PROFILE,
  payload,
})

export const fetchComparisonResults = taskId => {
  return async dispatch => {
    try {
      const response = await API.getComparisonResults(taskId)
      const { content, result, guid, document_name } = response.data
      const { kind_short, number } = content[0].document_meta_info
      const currentDocMetaInfo = {
        number,
        name: document_name,
        kindShort: kind_short,
      }
      await dispatch(setCurrentDocMetaInfo(currentDocMetaInfo))
      await dispatch(setSourceDocumentStructureElements(content[0]))
      await dispatch(setComparisonResults(result, guid))
    } catch (err) {
      console.error(err)
      errorMessage(err, 'Ошибка загрузки документов для сравнения')
    }
  }
}

export const getSimilarDocStructure = id => {
  return async dispatch => {
    try {
      const docsStructureResponse = await API.getDocumentStructure(id)
      const preparedStructure = {
        ...docsStructureResponse.data,
        document_structure_elements: docsStructureResponse.data.document_structure_elements.map(
          ({ number, full_text, internal_id, path_json }) => ({
            element_number: number.toString(),
            path: JSON.parse(path_json),
            full_text,
            internal_id,
          }),
        ),
      }
      dispatch(setSimilarDocsStructure(preparedStructure))
    } catch (err) {
      console.error(err)
      errorMessage(err, 'Ошибка при загрузке документа')
    }
  }
}

export const fetchHistoryDocs = () => {
  return async (dispatch, getState) => {
    const pageSize = getState().main.historyDocs.listSize
    try {
      const checkedDocsResponse = await API.getCheckedDocs(pageSize)
      dispatch(setHistoryDocs(checkedDocsResponse.data.results))
    } catch (err) {
      console.error(err)
      errorMessage(err, 'Ошибка при загрузке истории документов')
    }
  }
}
