import React, { useState } from 'react'
import './index.styl'
import { Button, Form, Input, message } from 'antd'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { setTrimmedUserNameFields, validateName, validatePass } from 'src/common/utils'
import { usePasswordType } from 'src/common'
import { mainPageErrorMessage } from 'src/common/notifications'
import { API } from 'src/api'

const RegisterPage = () => {
  const [form] = Form.useForm()
  const [showPasswordExtra, setShowPasswordExtra] = useState(true)
  const pass = usePasswordType()
  const passConfirm = usePasswordType()
  const history = useHistory()

  const onFinish = async ({ email, firstname, lastname, password, confirm }) => {
    try {
      await API.register({
        email,
        password,
        password2: confirm,
        username: email,
        first_name: firstname,
        last_name: lastname,
      })
      message.success(
        'Письмо с подтверждением регистрации было отправлено на вашу электронную почту! Для завершения регистрации пройдите по ссылке, указанной в письме',
        10,
      )
      history.push('/login')
    } catch (error) {
      const errorMessageObj = error?.response?.data
      if (!errorMessageObj) mainPageErrorMessage(error, 'Ошибка регистрации')
      else {
        for (const key in errorMessageObj) {
          key !== 'username' && mainPageErrorMessage(error, errorMessageObj[key])
        }
      }
    }
  }

  const onValuesChange = values => setTrimmedUserNameFields(values, form.setFieldsValue)

  return (
    <section className='register-wrapper'>
      <div className='register-title'>
        <Link className='register-link' to='/auth'>
          Вход в систему
        </Link>
        <h2>Регистрация</h2>
      </div>

      <Form
        form={form}
        name='register'
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        colon={false}
        requiredMark={false}
      >
        <Form.Item
          label='Фамилия'
          name='lastname'
          rules={[{ required: true, message: 'Пожалуйста, укажите свою фамилию.' }, validateName]}
        >
          <Input placeholder='Введите свою фамилию' />
        </Form.Item>

        <Form.Item
          label='Имя'
          name='firstname'
          rules={[{ required: true, message: 'Пожалуйста, укажите свое имя.' }, validateName]}
        >
          <Input placeholder='Введите свое имя' />
        </Form.Item>

        <Form.Item
          name='email'
          label='E-mail'
          rules={[
            {
              type: 'email',
              message: 'Пожалуйста, укажите корректный E-mail.',
            },
            {
              required: true,
              message: 'Пожалуйста, укажите свой E-mail.',
            },
            validateName,
          ]}
        >
          <Input placeholder='Введите e-mail' />
        </Form.Item>

        <Form.Item
          label='Пароль'
          name='password'
          extra={
            showPasswordExtra
              ? 'Минимум 8 знаков: строчные и заглавные латинские буквы, цифры и символы'
              : ''
          }
          rules={[
            { required: true, message: 'Пожалуйста, введите пароль.' },
            () => ({
              validator(_, value) {
                const validMessage = validatePass(value)
                if (value && validMessage !== 'valid') {
                  setShowPasswordExtra(false)
                  return Promise.reject(validMessage)
                } else {
                  return Promise.resolve()
                }
              },
            }),
          ]}
        >
          <Input placeholder='Введите пароль' {...pass} />
        </Form.Item>

        <Form.Item
          label='Повтор пароля'
          name='confirm'
          dependencies={['password']}
          rules={[
            { required: true, message: 'Пожалуйста, подтвердите пароль.' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('Пароли не совпадают.'))
              },
            }),
          ]}
        >
          <Input placeholder='Введите пароль' {...passConfirm} />
        </Form.Item>

        <Form.Item>
          <Button type='primary auth-submit-btn' htmlType='submit'>
            Создать аккаунт
          </Button>
        </Form.Item>
      </Form>
    </section>
  )
}

export default RegisterPage
