import React from 'react'
import { Button, Drawer, Space } from 'antd'

import './index.styl'

export const DrawerFilter = ({ visible, onClose, onClear, children }) => {
  return (
    <Drawer
      placement='right'
      width={500}
      visible={visible}
      onClose={onClose}
      extra={
        <Space>
          <Button className='drawer-btn' onClick={onClose}>
            Применить
          </Button>
          <Button className='drawer-btn' onClick={onClear}>
            Очистить
          </Button>
        </Space>
      }
    >
      {children}
    </Drawer>
  )
}
