import React from 'react'
import { SvgIcon } from 'src/components/SvgIcon'

export const SuffixMenu = ({ onChangeClick, onCancelClick }) => (
  <div className='icon-wrapper'>
    <div className='check-icon' onClick={onChangeClick}>
      <SvgIcon className='password-icon check-icon' iconName='check' />
    </div>
    <div className='cancel-icon' onClick={onCancelClick}>
      <SvgIcon className='password-icon cancel-icon' iconName='cancel' />
    </div>
  </div>
)
