import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { persistReducer, persistStore } from 'redux-persist'
import localForage from 'localforage'
import { difference } from 'lodash'

import { initialState as cashedResultsInitialState } from '../reducers/cashedResultsReducer/cashedResults-reducer'

import createReducer from './create-reducer'
import { requestInterceptor, responseInterceptor } from './interceptors'

const isCashedResultsStateHasNewStructure = (stateFromIndexedDB, stateFromInitialState) =>
  Boolean(difference(Object.keys(stateFromIndexedDB), Object.keys(stateFromInitialState)).length)

const logOutIfCashResultsStructureWasUpdated = () => next => async action => {
  if (action.type === 'persist/REHYDRATE') {
    const rehydratedCashedResultsState = action?.payload?.cashedResults
    if (
      rehydratedCashedResultsState &&
      isCashedResultsStateHasNewStructure(rehydratedCashedResultsState, cashedResultsInitialState)
    ) {
      await localForage.clear()
      window.location.reload()
      return undefined
    }
  }
  return next(action)
}

const createStoreWithPersistor = (initialState = {}) => {
  const middlewares = [thunk, logOutIfCashResultsStructureWasUpdated]
  const enhancers = []

  const __DEV__ = process.env.NODE_ENV !== 'production'
  const composeEnhancers = (__DEV__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

  const persistConfig = {
    key: 'root',
    storage: localForage,
    whitelist: ['session', 'cashedResults'],
    debug: __DEV__,
  }

  const configureReducer = persistReducer(persistConfig, createReducer())

  const store = createStore(
    configureReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares), ...enhancers),
  )

  requestInterceptor(store)
  responseInterceptor(store)

  const persistor = persistStore(store)

  store.asyncReducers = {}

  return { store, persistor }
}

export default createStoreWithPersistor
