import React from 'react'
import { Button } from 'antd'
import { SvgIcon } from 'src/components/SvgIcon'

import './StatusMessage.styl'

const StatusMessage = ({ currentDocName, onOkButtonClick }) => (
  <div className='status-message main-text'>
    <SvgIcon className='icon-size icon-check' iconName='success' />
    <div style={{ marginLeft: 10 }}>
      <div>Вы успешно отправили на проверку документы:</div>
      <div>{`"${currentDocName}"`}</div>
      <div>По окончании проверки все документы будут автоматически загружены на ваш диск.</div>
    </div>
    <Button className='button-ok button-success' onClick={onOkButtonClick}>
      Ok
    </Button>
  </div>
)

export const MemoizedStatusMessage = React.memo(StatusMessage)
