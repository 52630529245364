import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { setFilters, setSortLevelBtn } from 'src/modules/reducers/mainReducer/main-actions'
import { Button, DatePicker, Select, Checkbox } from 'antd'
import { SvgIcon } from 'src/components/SvgIcon'
import moment from 'moment'

import './Filters.styl'

const { Option } = Select
const { RangePicker } = DatePicker
const dataFormat = 'YYYY.MM.DD'
const defaultValues = {
  rangePicker: null,
  selectSort: 'По дате проверки',
  verificationPassedCheckbox: false,
  verificationFailedCheckbox: false,
}

export const Filters = ({ isClear, filterValues }) => {
  const dispatch = useDispatch()
  const { handleSubmit, control, reset, watch } = useForm(
    Object.keys(filterValues).length && filterValues,
  )
  const onSubmit = data => dispatch(setFilters(data))
  const [typeSort, setTypeSort] = useState('desc')

  const handleSortLevelBtn = () => {
    setTypeSort(prevState => (prevState === 'asc' ? 'desc' : 'asc'))
    dispatch(setSortLevelBtn(typeSort))
  }

  useEffect(() => {
    reset(defaultValues)
  }, [isClear, reset])

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (!name?.endsWith('Slider')) {
        handleSubmit(onSubmit)()
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, handleSubmit, onSubmit])

  useEffect(() => {
    reset(defaultValues)
    dispatch(setFilters(defaultValues))
    dispatch(setSortLevelBtn('asc'))
  }, [isClear, reset])

  return (
    <form>
      <div className='filter-container'>
        <h1 className='filter-title'>Сортировка</h1>
        <div className='filter-content'>
          <Controller
            name='selectSort'
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                className='filter-select'
                value={value}
                onChange={onChange}
                suffixIcon={<SvgIcon className='expend-icon' iconName='select-triangle' />}
              >
                <Option value='byVerificationDate'>По дате проверки</Option>
              </Select>
            )}
          />

          <Button namebtn='filter-sort-btn' onClick={handleSortLevelBtn}>
            <SvgIcon className='filter-sort-btn-svg' iconName='arrow-button' />
          </Button>
        </div>
      </div>

      <div className='filter-container'>
        <div className='filter-container-header'>
          <h1 className='filter-title'>Фильтры</h1>
          {/* <p>
            Найдено документов: <span>{totalResults}</span>
          </p> */}
        </div>

        <div className='filter-subCotainer'>
          <h2 className='filter-subTitle'>Статус</h2>
          <Controller
            name='verificationPassedCheckbox'
            control={control}
            render={({ field: { onChange, value } }) => (
              <Checkbox onChange={e => onChange(e.target.checked)} checked={value}>
                Проверка пройдена
              </Checkbox>
            )}
          />
          <Controller
            name='verificationFailedCheckbox'
            control={control}
            render={({ field: { onChange, value } }) => (
              <Checkbox onChange={e => onChange(e.target.checked)} checked={value}>
                Проверка не пройдена
              </Checkbox>
            )}
          />
        </div>

        <div className='filter-subCotainer'>
          <h2 className='filter-subTitle'>Дата проверки</h2>
          <Controller
            name='rangePicker'
            control={control}
            render={({ field: { onChange, value } }) => (
              <RangePicker
                className='filter-dataPicker'
                format={dataFormat}
                value={[value && moment(value[0]), value && moment(value[1])]}
                onChange={dataRange =>
                  onChange(dataRange ? dataRange.map(item => item.format(dataFormat)) : null)
                }
                placeholder=''
                separator=''
              />
            )}
          />
        </div>
      </div>
    </form>
  )
}
