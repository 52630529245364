import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { List } from 'antd'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import { getURL } from 'src/modules/router/getRoutes'
import { setActiveElementHighlightedFullText } from 'src/modules/reducers/cashedResultsReducer/cashedResults-actions'
import { SvgIcon } from 'src/components/SvgIcon'

import './index.styl'

const getCoefficient = str => `${str.toFixed(0)}%`

const Source = ({
  similarFragments,
  filteredSimilarFragments,
  isFragmentLoading,
  documentStructure,
  numberOfMatchedFragments,
}) => {
  const dispatch = useDispatch()
  const { task_id } = useSelector(state => state.main)

  const fragmentsList = (
    <List
      dataSource={filteredSimilarFragments}
      itemLayout='horizontal'
      renderItem={item => (
        <List.Item style={{ alignItems: 'flex-start' }}>
          <List.Item.Meta
            title={
              <div className='source-title-wrapper'>
                <Link
                  to={getURL.similarDocuments(
                    item.similar_document_id,
                    item.similar_fragment_element_number,
                    task_id,
                  )}
                  className='source-title'
                >
                  {item.similar_document_name}
                </Link>
                <div className='source-value'>
                  <span>{getCoefficient(item.jakkar_distance)}</span>
                  <span>{getCoefficient(item.jakkar_min_distance)}</span>
                  <span>{getCoefficient(item.cosine_distance)}</span>
                </div>
              </div>
            }
            description={
              <p
                className={cn(
                  { 'full-citation': item.jakkar_distance === 100 },
                  'source-desc-content',
                )}
                onMouseEnter={() =>
                  dispatch(setActiveElementHighlightedFullText(item.source_fragment_text))
                }
                onMouseLeave={() => dispatch(setActiveElementHighlightedFullText(null))}
                dangerouslySetInnerHTML={{ __html: item.similar_fragment_text }}
              />
            }
          />
        </List.Item>
      )}
    />
  )

  const SourceContent = () => {
    if (filteredSimilarFragments.length) return fragmentsList

    const emptyFragmentsPlaceHolder =
      'Не найдено фрагментов, соответствующих выбранным фильтрам. Выберите меньший процент соответствия или измените фильтр по виду документов.'
    const emptyFilteredFragmentsPlaceHolder =
      'Нажмите на выделенные фрагменты в тексте документа либо на цветовые отрезки верхней панели навигации. Ознакомьтесь с найденными схожими положениями в нормативных правовых актах и результатами сравнения.'

    const getSimilarFragments = () => {
      if (isFragmentLoading || !documentStructure?.length) {
        return null
      }

      if (numberOfMatchedFragments) {
        return similarFragments.length
          ? emptyFragmentsPlaceHolder
          : emptyFilteredFragmentsPlaceHolder
      }

      return (
        <div className='not-found'>
          Схожие фрагменты не найдены
          <SvgIcon className='not-found-icon' iconName='not-found' />
        </div>
      )
    }

    return <div className='source-text-default'>{getSimilarFragments()}</div>
  }

  return (
    <div className='Source-root'>
      <div className='source-all-text'>
        <SourceContent />
      </div>
    </div>
  )
}

export default Source
