import {
  SET_JAKKAR_DISTANCE_FILTER,
  SET_JAKKAR_MIN_DISTANCE_FILTER,
  SET_COSINE_DISTANCE_FILTER,
  SET_SIMILAR_FRAGMENTS_DOC_KINDS,
  SET_SIMILAR_FRAGMENTS_SORT_FIELD,
  SET_SIMILAR_FRAGMENTS_SORT_ORDER,
  SET_FRAGMENTS_SCROLL,
  SET_ACTIVE_ELEMENT,
  SET_ACTIVE_ELEMENT_HIGHLIGHTED_FULL_TEXT,
  SET_DATE_RANGE_FILTER,
  SET_DOCUMENT_STRUCTURE_FILTERS,
  CLEAR_STATE,
  CASHED_RESULTS_SET_FILTERS,
  SET_FIRST_SORT_LEVEL_BTN,
  SET_SECOND_SORT_LEVEL_BTN,
  SET_THIRD_SORT_LEVEL_BTN,
  SET_FIRST_SORT_LEVEL,
} from './cashedResults-constants'

export const initialState = {
  fragmentsScroll: 0,
  activeElementHighlightedFullText: null,
  activeElement: null,
  // :TODO Фильтрация и сортировка dateRangeFilter, documentStructureFilters,
  //  similarFragments сейчас не используются,
  //  если будет необходимость, можно будет заюзать в новой версии фильтров,
  //  в противном случае удалить (также из диспатчера, редьюсера и селектора)
  dateRangeFilter: null,
  documentStructureFilters: {
    isFullCitation: false,
    isProcessedWording: false,
    isUniqWording: false,
  },
  similarFragments: {
    sortField: '',
    sortOrder: 'pag',
    jakkarDistanceFilter: 0,
    jakkarMinDistanceFilter: 0,
    cosineDistanceFilter: 0,
    docKinds: [],
    orderedKinds: [
      'Закон РФ о поправке к Конституции',
      'Федеральный конституционный закон',
      'Федеральный закон',
      'Указ Президента',
      'Распоряжение Президента',
      'Постановление Правительства',
      'Распоряжение Правительства',
      'Приказ',
    ],
  },
  filters: {
    firstSortLevel: '',
    secondSortLevel: '',
    thirdSortLevel: '',
    jakkarSlider: [0, 100],
    minSlider: [0, 100],
    cosineSlider: [0, 100],
    similarFragmentsDocKinds: [],
    rangePicker: null,
  },
  firstSortLevelBtn: '',
  secondSortLevelBtn: '',
  thirdSortLevelBtn: '',
}

export const cashedResultsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FIRST_SORT_LEVEL: {
      const getFirstSortLevel = () => {
        if (action.payload === state.filters.firstSortLevel) {
          return state.firstSortLevelBtn === 'asc' ? 'desc' : 'asc'
        }

        return 'asc'
      }

      return {
        ...state,
        filters: {
          ...state.filters,
          firstSortLevel: action.payload,
          secondSortLevel: '',
          thirdSortLevel: '',
        },
        firstSortLevelBtn: getFirstSortLevel(),
        secondSortLevelBtn: 'asc',
        thirdSortLevelBtn: 'asc',
      }
    }
    case SET_FIRST_SORT_LEVEL_BTN: {
      return {
        ...state,
        firstSortLevelBtn: action.payload,
      }
    }
    case SET_SECOND_SORT_LEVEL_BTN: {
      return {
        ...state,
        secondSortLevelBtn: action.payload,
      }
    }
    case SET_THIRD_SORT_LEVEL_BTN: {
      return {
        ...state,
        thirdSortLevelBtn: action.payload,
      }
    }
    case CASHED_RESULTS_SET_FILTERS: {
      return {
        ...state,
        filters: action.payload,
      }
    }
    case SET_JAKKAR_DISTANCE_FILTER: {
      return {
        ...state,
        similarFragments: {
          ...state.similarFragments,
          jakkarDistanceFilter: action.payload,
        },
      }
    }
    case SET_JAKKAR_MIN_DISTANCE_FILTER: {
      return {
        ...state,
        similarFragments: {
          ...state.similarFragments,
          jakkarMinDistanceFilter: action.payload,
        },
      }
    }
    case SET_COSINE_DISTANCE_FILTER: {
      return {
        ...state,
        similarFragments: {
          ...state.similarFragments,
          cosineDistanceFilter: action.payload,
        },
      }
    }
    case SET_SIMILAR_FRAGMENTS_DOC_KINDS: {
      return {
        ...state,
        similarFragments: {
          ...state.similarFragments,
          docKinds: action.payload,
        },
      }
    }
    case SET_SIMILAR_FRAGMENTS_SORT_FIELD: {
      return {
        ...state,
        similarFragments: {
          ...state.similarFragments,
          sortField: action.payload,
        },
      }
    }
    case SET_SIMILAR_FRAGMENTS_SORT_ORDER: {
      return {
        ...state,
        similarFragments: {
          ...state.similarFragments,
          sortOrder: action.payload,
        },
      }
    }
    case SET_FRAGMENTS_SCROLL: {
      return {
        ...state,
        fragmentsScroll: action.payload,
      }
    }
    case SET_ACTIVE_ELEMENT: {
      return {
        ...state,
        activeElement: action.payload,
      }
    }
    case SET_ACTIVE_ELEMENT_HIGHLIGHTED_FULL_TEXT: {
      return {
        ...state,
        activeElementHighlightedFullText: action.payload,
      }
    }
    case SET_DATE_RANGE_FILTER: {
      return {
        ...state,
        dateRangeFilter: action.payload,
      }
    }
    case SET_DOCUMENT_STRUCTURE_FILTERS: {
      return {
        ...state,
        documentStructureFilters: { ...state.documentStructureFilters, ...action.payload },
      }
    }
    case CLEAR_STATE: {
      return initialState
    }
    default:
      return state
  }
}
