import React, { useState } from 'react'
import Dialog from 'src/components/Dialog'
import { Button, Form, Input, message } from 'antd'
import { usePasswordType } from 'src/common'
import { validatePass } from 'src/common/utils'

import './PassUpdateDialog.styl'

export const PassUpdateDialog = ({ visible, setVisible }) => {
  const [form] = Form.useForm()
  const passOld = usePasswordType()
  const passNew = usePasswordType()
  const passConfirm = usePasswordType()
  const [showPasswordExtra, setShowPasswordExtra] = useState(true)

  const handleCancel = () => {
    form.resetFields()
    setVisible(false)
  }

  const onFinish = () => {
    // console.log('Success:', values)
    setTimeout(() => {
      setVisible(false)
      message.success('Ссылка для восстановления пароля была отправлена на электронную почту')
    }, 300)
  }

  const onFinishFailed = () => {
    // console.log('Failed:', errorInfo)
  }

  const DialogBody = (
    <Form
      form={form}
      name='pass-update'
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      colon={false}
      requiredMark={false}
    >
      <Form.Item
        label='Текущий пароль'
        name='current-pass'
        rules={[{ required: true, message: 'Пожалуйста, введите текущий пароль.' }]}
      >
        <Input placeholder='Введите текущий пароль' {...passOld} />
      </Form.Item>

      <Form.Item
        label='Новый пароль'
        name='password'
        extra={
          showPasswordExtra
            ? 'Минимум 8 знаков: строчные и заглавные латинские буквы, цифры и символы'
            : ''
        }
        rules={[
          { required: true, message: 'Пожалуйста, введите новый пароль.' },
          () => ({
            validator(rule, value) {
              const validMessage = validatePass(value)
              if (value && validMessage !== 'valid') {
                setShowPasswordExtra(false)
                return Promise.reject(validMessage)
              } else {
                return Promise.resolve()
              }
            },
          }),
        ]}
      >
        <Input placeholder='Введите новый пароль' {...passNew} />
      </Form.Item>

      <Form.Item
        label='Подтверждение пароля'
        name='confirm'
        dependencies={['password']}
        rules={[
          { required: true, message: 'Пожалуйста, подтвердите пароль.' },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve()
              }
              return Promise.reject('Введенные пароли не совподают.')
            },
          }),
        ]}
      >
        <Input placeholder='Подтвердите новый пароль' {...passConfirm} />
      </Form.Item>

      <div className='buttons'>
        <Form.Item>
          <Button type='primary pass-recovery-btn update-form-btn' htmlType='submit'>
            Изменить
          </Button>
        </Form.Item>
        <Form.Item className='buttons'>
          <Button type='secondary pass-recovery-btn update-form-btn' onClick={handleCancel}>
            Отменить
          </Button>
        </Form.Item>
      </div>
    </Form>
  )

  return (
    <Dialog
      title='Изменить пароль'
      visible={visible}
      setVisible={setVisible}
      hideButtonClose={true}
    >
      {DialogBody}
    </Dialog>
  )
}
