import React, { useEffect, useState } from 'react'
import { Button, DatePicker, Select, Checkbox, Slider, InputNumber } from 'antd'
import { useForm, Controller } from 'react-hook-form'
import { SvgIcon } from 'src/components/SvgIcon'
import { useDispatch, useSelector } from 'react-redux'
import {
  setDocumentStructureFilters,
  setFilters,
  setFirstSortLevelBtn,
  setSecondSortLevelBtn,
  setThirdSortLevelBtn,
} from 'src/modules/reducers/cashedResultsReducer/cashedResults-actions'
import { cashedResults } from 'src/modules/selectors'
import moment from 'moment'

import './Filters.styl'

const { Option } = Select
const { RangePicker } = DatePicker
const dataFormat = 'YYYY.MM.DD'
const defaultValues = {
  firstSortLevel: '',
  secondSortLevel: '',
  thirdSortLevel: '',
  jakkarSlider: [0, 100],
  minSlider: [0, 100],
  cosineSlider: [0, 100],
  similarFragmentsDocKinds: [],
  rangePicker: null,
}

export const Filters = ({
  isClear,
  documentKinds,
  similarFragmentsNumber,
  filterValues,
  tagKey,
}) => {
  const dispatch = useDispatch()
  const { firstSortLevelBtn, secondSortLevelBtn, thirdSortLevelBtn } = useSelector(cashedResults)
  const { handleSubmit, control, reset, watch, setValue } = useForm({
    defaultValues: Object.keys(filterValues).length && filterValues,
  })
  const [isChangeInputValue, setIsChangeInputValue] = useState(false)
  const onSubmit = data => {
    return dispatch(setFilters(data))
  }

  const handleSortLevelBtn = value => () => {
    switch (value) {
      case 'first':
        const firstSortType = firstSortLevelBtn === 'asc' ? 'desc' : 'asc'
        dispatch(setFirstSortLevelBtn(firstSortType))
        break
      case 'second':
        const secondSortType = secondSortLevelBtn === 'asc' ? 'desc' : 'asc'
        dispatch(setSecondSortLevelBtn(secondSortType))
        break
      case 'third':
        const thirdSortType = secondSortLevelBtn === 'asc' ? 'desc' : 'asc'
        dispatch(setThirdSortLevelBtn(thirdSortType))
        break
    }
  }

  const handleSliderAfterChange = () => {
    handleSubmit(onSubmit)()
  }

  const handleFastFilters = () => {
    dispatch(setDocumentStructureFilters('isFullCitation', true))
  }

  useEffect(() => {
    const [key, value] = tagKey
    if (!Array.isArray(value) && value?.endsWith('Slider')) setValue(key, [0, 100])
    else setValue(key, filterValues[key])
  }, [tagKey])

  // set values from state to form
  useEffect(() => {
    setValue('firstSortLevel', filterValues.firstSortLevel)
    setValue('secondSortLevel', filterValues.secondSortLevel)
    setValue('thirdSortLevel', filterValues.thirdSortLevel)
  }, [filterValues])

  useEffect(() => {
    reset(defaultValues)
    dispatch(
      setFilters({
        firstSortLevel: filterValues.firstSortLevel,
        secondSortLevel: '',
        thirdSortLevel: '',
        jakkarSlider: [0, 100],
        minSlider: [0, 100],
        cosineSlider: [0, 100],
        similarFragmentsDocKinds: [],
        rangePicker: null,
      }),
    )
    dispatch(setDocumentStructureFilters('isFullCitation', false))
    dispatch(setFirstSortLevelBtn('asc'))
    dispatch(setSecondSortLevelBtn('asc'))
    dispatch(setThirdSortLevelBtn('asc'))
  }, [isClear, reset])

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (!name?.endsWith('Slider')) {
        handleSubmit(onSubmit)()
      }
      if (isChangeInputValue) {
        handleSubmit(onSubmit)()
        setIsChangeInputValue(false)
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, handleSubmit, onSubmit])

  return (
    <form>
      <div className='filter-container'>
        <h1 className='filter-title'>Сортировка</h1>

        <div className='filter-inlineContainer'>
          <div className='filter-col'>
            <p>1-ый уровень</p>
            <div className='filter-content'>
              <Controller
                name='firstSortLevel'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    className='filter-select'
                    value={filterValues.firstSortLevel || value}
                    onChange={onChange}
                    suffixIcon={<SvgIcon className='expend-icon' iconName='select-triangle' />}
                  >
                    <Option value=''>...</Option>
                    <Option value='jakkar_distance'>Жаккар</Option>
                    <Option value='jakkar_min_distance'>Минимум</Option>
                    <Option value='cosine_distance'>Косинус</Option>
                  </Select>
                )}
              />
              <Button namebtn='filter-sort-btn' onClick={handleSortLevelBtn('first')}>
                <SvgIcon
                  className={`filter-sort-btn-svg ${firstSortLevelBtn === 'desc' ? 'rotate' : ''}`}
                  iconName='arrow-button'
                />
              </Button>
            </div>
          </div>

          <div className='filter-col'>
            <p>2-ый уровень</p>
            <div className='filter-content'>
              <Controller
                name='secondSortLevel'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    className='filter-select'
                    value={!filterValues.firstSortLevel ? '' : value}
                    onChange={onChange}
                    disabled={!filterValues.firstSortLevel}
                    suffixIcon={<SvgIcon className='expend-icon' iconName='select-triangle' />}
                  >
                    <Option value=''>...</Option>
                    <Option value='jakkar_distance'>Жаккар</Option>
                    <Option value='jakkar_min_distance'>Минимум</Option>
                    <Option value='cosine_distance'>Косинус</Option>
                  </Select>
                )}
              />
              <Button
                namebtn='filter-sort-btn'
                onClick={handleSortLevelBtn('second')}
                disabled={!filterValues.firstSortLevel}
              >
                <SvgIcon
                  className={`filter-sort-btn-svg ${secondSortLevelBtn === 'desc' ? 'rotate' : ''}`}
                  iconName='arrow-button'
                />
              </Button>
            </div>
          </div>

          <div className='filter-col'>
            <p>3-ый уровень</p>
            <div className='filter-content'>
              <Controller
                name='thirdSortLevel'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    className='filter-select'
                    value={
                      !filterValues.firstSortLevel || !filterValues.secondSortLevel ? '' : value
                    }
                    onChange={onChange}
                    disabled={!filterValues.firstSortLevel || !filterValues.secondSortLevel}
                    suffixIcon={<SvgIcon className='expend-icon' iconName='select-triangle' />}
                  >
                    <Option value=''>...</Option>
                    <Option value='jakkar_distance'>Жаккар</Option>
                    <Option value='jakkar_min_distance'>Минимум</Option>
                    <Option value='cosine_distance'>Косинус</Option>
                  </Select>
                )}
              />
              <Button
                namebtn='filter-sort-btn'
                onClick={handleSortLevelBtn('third')}
                disabled={!filterValues.secondSortLevel}
              >
                <SvgIcon
                  className={`filter-sort-btn-svg ${thirdSortLevelBtn === 'desc' ? 'rotate' : ''}`}
                  iconName='arrow-button'
                />
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className='filter-container'>
        <div className='filter-container-header'>
          <h1 className='filter-title'>Фильтры</h1>
          <p>
            Найдено документов: <span>{similarFragmentsNumber}</span>
          </p>
        </div>

        <div className='filter-subCotainer'>
          <h2 className='filter-subTitle'>Быстрые фильтры</h2>
          <Button
            className='ant-btn-link-primary filter-btn-link'
            type='link'
            onClick={handleFastFilters}
          >
            Только полное цитирование
          </Button>
          {/* <Button className='ant-btn-link-primary filter-btn-link' type='link'>
            Другой быстрый фильтр
          </Button> */}
        </div>

        <div className='filter-subCotainer'>
          <h2 className='filter-subTitle'>Параметры</h2>
          <div className='filter-inlineContainer'>
            <div className='filter-col'>
              <p>Жаккар</p>
              <div className='filter-params'>
                <Controller
                  name='jakkarSlider'
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    const changeHandler = currentValue => {
                      onChange([currentValue, value[1]])
                      setIsChangeInputValue(true)
                    }
                    return (
                      <InputNumber
                        type='number'
                        className='filter-params-input-1'
                        min={0}
                        max={100}
                        size='small'
                        value={value[0]}
                        onChange={changeHandler}
                      />
                    )
                  }}
                />
                <Controller
                  name='jakkarSlider'
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    const changeHandler = currentValue => {
                      onChange([value[0], currentValue])
                      setIsChangeInputValue(true)
                    }
                    return (
                      <InputNumber
                        type='number'
                        className='filter-params-input-2'
                        min={0}
                        max={100}
                        size='small'
                        value={value[1]}
                        onChange={changeHandler}
                      />
                    )
                  }}
                />

                <Controller
                  name='jakkarSlider'
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Slider
                      className='filter-params-slider'
                      min={0}
                      max={100}
                      range
                      step={1}
                      value={value}
                      onChange={onChange}
                      onAfterChange={handleSliderAfterChange}
                    />
                  )}
                />
              </div>
            </div>

            <div className='filter-col'>
              <p>Минимум</p>
              <div className='filter-params'>
                <Controller
                  name='minSlider'
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    const changeHandler = currentValue => {
                      onChange([currentValue, value[1]])
                      setIsChangeInputValue(true)
                    }
                    return (
                      <InputNumber
                        type='number'
                        className='filter-params-input-1'
                        min={0}
                        max={100}
                        size='small'
                        value={value[0]}
                        onChange={changeHandler}
                      />
                    )
                  }}
                />

                <Controller
                  name='minSlider'
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    const changeHandler = currentValue => {
                      onChange([value[0], currentValue])
                      setIsChangeInputValue(true)
                    }
                    return (
                      <InputNumber
                        type='number'
                        className='filter-params-input-2'
                        min={0}
                        max={100}
                        size='small'
                        value={value[1]}
                        onChange={changeHandler}
                      />
                    )
                  }}
                />

                <Controller
                  name='minSlider'
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Slider
                      className='filter-params-slider'
                      min={0}
                      max={100}
                      range
                      step={1}
                      value={value}
                      onChange={onChange}
                      onAfterChange={handleSliderAfterChange}
                    />
                  )}
                />
              </div>
            </div>

            <div className='filter-col'>
              <p>Косинус</p>
              <div className='filter-params'>
                <Controller
                  name='cosineSlider'
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    const changeHandler = currentValue => {
                      onChange([currentValue, value[1]])
                      setIsChangeInputValue(true)
                    }
                    return (
                      <InputNumber
                        type='number'
                        className='filter-params-input-1'
                        min={0}
                        max={100}
                        size='small'
                        value={value[0]}
                        onChange={changeHandler}
                      />
                    )
                  }}
                />

                <Controller
                  name='cosineSlider'
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    const changeHandler = currentValue => {
                      onChange([value[0], currentValue])
                      setIsChangeInputValue(true)
                    }
                    return (
                      <InputNumber
                        type='number'
                        className='filter-params-input-2'
                        min={0}
                        max={100}
                        size='small'
                        value={value[1]}
                        onChange={changeHandler}
                      />
                    )
                  }}
                />

                <Controller
                  name='cosineSlider'
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Slider
                      className='filter-params-slider'
                      min={0}
                      max={100}
                      range
                      step={1}
                      value={value}
                      onChange={onChange}
                      onAfterChange={handleSliderAfterChange}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='filter-subCotainer'>
          <h2 className='filter-subTitle'>Коллекции</h2>
          <Controller
            name='similarFragmentsDocKinds'
            control={control}
            render={({ field: { onChange, value } }) => (
              <Checkbox.Group
                className='filter-checkboxGoup'
                options={documentKinds}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </div>

        <div className='filter-subCotainer'>
          <h2 className='filter-subTitle'>Дата проверки</h2>
          <Controller
            name='rangePicker'
            control={control}
            render={({ field: { onChange, value } }) => (
              <RangePicker
                className='filter-dataPicker'
                format={dataFormat}
                placeholder=''
                separator=''
                value={[value && moment(value[0]), value && moment(value[1])]}
                onChange={dataRange => {
                  onChange(dataRange ? dataRange.map(item => item.format(dataFormat)) : null)
                }}
              />
            )}
          />
        </div>
      </div>
    </form>
  )
}
